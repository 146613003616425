// =============================================

// About Page

// =============================================


.l-about-page {
  > * + * {
    margin-top: 120px;

    @include mq($bp__phablet) {
      margin-top: 200px;
    }
  }
}

// Applies to story text
.about__content {
  position: relative;
  @include clearfix;

  @include mq(400px) {
    margin-left: 10px;
  }

  @include mq($bp__phablet) {
    margin-left: 20px;
  }

  @include mq($bp__tablet) {
    margin-left: 60px;
  }

  @include mq($bp__desktop) {
    margin-left: 80px;
  }
}

// --------------------------------------------
// Story
// --------------------------------------------

.about-story__fig {
  margin-top: -29px;
  @include mq($bp__phablet) {
    margin-top: -35px;
  }
  @include mq($bp__tablet) {
    margin-top: -45px;
  }
}

.about-story__h {
  margin-top: -19px;
  @include mq($bp__phablet) {
    margin-top: -29px;
  }
  @include mq($bp__tablet) {
    margin-top: -45px;
  }
}

.about__shape-bg {
  display: none;

  @include mq($bp__desktop--xl) {
    display: block;
    right: -20px;
    top: -20%;
    width: 40%;
  }
}

.about-story__h .resp-txt {
  @include mq(375px, 450px) {
    display: block;
  }
  @include mq(550px, 600px) {
    display: block;
  }
  @include mq(630px, $bp__tablet) {
    display: block;
  }
}

.l-about-story__highlight {
  max-width: 900px;
}

.about-story__highlight {
  height: auto;
  display: none;

  @include mq(550px) {
    display: block;
    float: right;
    @include margin(6px n 10px 40px);
  }
}

.about-story__highlight__inner {
  padding: 45px 17px 50px;
}


// --------------------------------------------
// Team
// --------------------------------------------

.about-team {
  position: relative;
}

.about-team__shape-bg {
  top: -100px;
  left: -170px;
  width: 33%;
  min-width: 325px;
}


//
// Kathryn
//

.about-founder__nameplate {
  position: relative;

  @include circle(240px);
  @include margin(-65px auto 80px);
  padding: 30px 10px;

  text-align: center;
  background-color: $blue-dk;

  @include mq(400px) {
    margin-top: -100px;
  }

  @include mq($bp__phablet) {
    @include circle(300px);
    @include margin(-140px 0 100px);
    padding: 40px 20px;
  }

  &:after,
  &:before {
    @include psuedo;
    @include absolute-center(horizontal);
    width: 40px;
    height: 3px;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: #fff;
    transition: all .2s $timing--butter;
  }
  &:before {
    top: 35px;
  }
  &:after {
    bottom: 35px;
  }
  a:hover &:before {
    transform: translateX(-50%)
               translateY(5px);
  }

  a:hover &:after {
    transform: translateX(-50%)
               translateY(-5px);
  }

}

.about-founder__overline {
  @include sans(14px, bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  color: $blue;
  @include mq($bp__phablet) {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.about-founder__h {
  @include serif(42px);
  line-height: 1;
  color: #fff;
  margin: 0;

  @include mq($bp__phablet) {
    font-size: 52px;
  }
}


@include mq(700px) {
  .about-founder__txt-col-1,
  .about-founder__txt-col-2 {
    float: left;
    width: 46%;
  }

  .about-founder__txt-col-2 {
    float: right;
    margin-top: -60px;
  }
}

.about-founder__dropcap {
  float: left;
  margin-right: 8px;
  margin-bottom: 10px;

  @include serif(104px);
  line-height: 0.2;
  color: $red;

  @include mq($bp__phablet) {
    font-size: 128px;
  }
}


// --------------------------------------------
// Partners
// --------------------------------------------

.l-about-partners {
  max-width: 1160px;
  margin-top: 30px;

  @include mq($bp__phablet) {
    margin-top: 60px;
  }

  @include mq($bp__desktop--xl) {
    margin-left: 80px;
  }
}

.about-partners {
  @include mq($bp__tablet) {
    @include grid(3, 50px);
  }

  @include mq($bp__desktop--xl) {
    @include grid(3, 80px);
  }
}

.about-partners__item {
  margin-bottom: 60px;
  @include mq($bp__tablet) {
    margin-bottom: 0;
  }
}

.about-partners__logo {
  position: absolute;
  max-width: 60px;
  max-height: 60px;

  @include mq($bp__phablet) {
    max-width: 65px;
    max-height: 65px;
  }
  @include mq($bp__tablet) {
    position: relative;
    margin-bottom: 20px;
  }
}

.about-partners__txt-mod {
  margin-left: 75px;
  @include mq($bp__phablet) {
    margin-left: 100px;
  }
  @include mq($bp__tablet) {
    margin-left: 0;
  }
}

.about-partners__overline {
  @include sans(13px, bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $blue-dk;
  margin: 0 0 6px;

  @include mq($bp__tablet) {
    font-size: 14px;
  }
}

.about-partners__h {
  @include serif(32px);
  color: $blue;
  margin: 0 0 14px;

  @include mq($bp--desktop) {
    font-size: 36px;
  }

  > a {
    text-decoration: none;
  }
  > a:hover {
    text-decoration: underline;
  }
}

.about-partners__txt {
  margin: 0;
  @include mq($bp--desktop) {
    max-width: 275px;
  }
}
