// =============================================

// Variables

// =============================================



// --------------------------------------------
// Color
// --------------------------------------------

$red: rgb(240,78,48);
$blue: rgb(0,187,214);
$blue-hover: rgb(20,176,199);
$blue-dark: rgb(31,32,52);
$blue-dk: $blue-dark;
$navy: $blue-dark;
$orange: rgb(244,121,32);
$beige: #fcfdf4; //original: rgb(255,254,242); dark: #FBFAEC;
$gray: rgb(170, 170, 170);
$gray-dark: rgb(100, 100, 100);
$gray-dk: $gray-dark;
$faint-gray: #fcfcfc;

$selection-color: $blue-dk;
$selection-color-type: #fff;

$link: $blue;
$link-hover: darken($link, 8%);
$link-gray: $gray;
$highlight: $navy;
$bg: $beige;
$bg--gray: rgb(235, 235, 239);



// --------------------------------------------
// Fonts
// --------------------------------------------

// Fallbacks
$sans-fallback:Arial,Helvetica,Verdana,sans-serif;
$serif-fallback:Georgia,Times,Times New Roman,serif;
$mono:"Courier New",Courier,mono;


// Named Fonts
$edmond: "Edmond", $sans-fallback;
$eksell-lg: "Eksell Large", $serif-fallback;
$eksell-md: "Eksell Medium", $serif-fallback;
$eksell-sm: "Eksell Small", $serif-fallback;
$eksell: $eksell-lg;


// Generic Names
$sans: $edmond;
$serif: $eksell;


// Font Weights
//$font-weight--light: 100; // not in use
$font-weight--reg: 300;
$font-weight--med: 500;
$font-weight--bold: 700;
//$font-weight--black: 900; // not in use



// --------------------------------------------
// Breakpoints
// --------------------------------------------

$bp__phone: 380px;
$bp__phablet: 450px;
$bp__tablet--sm: 600px;
$bp__tablet: 768px;
$bp__desktop: 960px;
$bp__desktop--xl: 1100px;


$bp__mobile-nav: 380px;
$bp__half-nav: 500px;
$bp__full-nav: 800px;



// --------------------------------------------
// Layout
// --------------------------------------------

$site-padding: 20px;
$site-max-width: 1200px;
$site-margin: 20px;

$nav-height: 80px;
$nav-padding: 20px;
$nav-padding--small: 10px;

$txt-max-width: 600px;
$txt-max-width--lg: 700px;

$blog-width: 800px;


// --------------------------------------------
// Animations
// --------------------------------------------

$timing: cubic-bezier(0.645,0.045,0.355,1);
$timing--butter: cubic-bezier(0.215, 0.61, 0.355, 1);
$timing--simmer: cubic-bezier(0.42,0,.58,1);
$timing--simmerOut: cubic-bezier(0,0,.58,1);
$timing--easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$timing--easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

// Timing function reference: http://easings.net/



// --------------------------------------------
// Z-Index
// --------------------------------------------

// Scale
$z-index--neg: -1;
$z-index--0:   0;
$z-index--1:   100;
$z-index--2:   200;
$z-index--3:   300;
$z-index--4:   400;
$z-index--5:   500;
$z-index--6:   600;
$z-index--7:   700;
$z-index--8:   800;
$z-index--9:   900;
$z-index--10: 1000;

// Application
$z-index--faux-background:    $z-index--neg;
$z-index--shape-swash:        $z-index--neg;

$z-index--pinstripe:          $z-index--1;
$z-index--shape__h:           $z-index--1;
$z-index--pick-color:         $z-index--1;
$z-index--tri-buy__color:     $z-index--1;
$z-index--hp-life__shape-bg:  $z-index--1;
$z-index--sub-nav:            $z-index--1;

$z-index--nag:                $z-index--2;




// --------------------------------------------
// Styles
// --------------------------------------------

$shape-border: 3px solid $red;




// --------------------------------------------
// Paths
// --------------------------------------------

$svg-path: "svg/";
$theme-path: "/wp-content/themes/bucaboot/";
