// =============================================

// Site Navigation

// =============================================

$overlay-nav-link: #FFFFFF;

// --------------------------------------------
// Header
// --------------------------------------------

.hdr {
  position: fixed;
  width: 100%;
  border-top: 20px solid $blue-dk;
  transition: all .2s $timing--butter;
  z-index: $z-index--3;
  transform: translateY(-20px);
  transition: transform 0.3s $timing--butter;
  
  .nav-is-hidden & {
    transform: translateY(-80px);
  }

  .nav-top & {
    transform: translateY(0);
  }
}

.hdr--overlay {
  background: 0;
  border-color: transparent;
  transform: translateY(0px);

  .nav__logo {
    display: none;
  }

  .l-nav__buy {
    display: none;
  }
}

.hdr__wrapper {
  height: 60px;
  width: 100%;
}

.hdr__container {
  max-width: $site-max-width;
  margin: 0 20px;
  position: relative;

  @include mq(1240px) {
    margin-left: auto;
    margin-right: auto;
  }
}

// --------------------------------------------
// Logo
// --------------------------------------------

.nav__logo {
  @include absolute-center(horizontal);
  width: 160px;
  margin: 10px 0 0 -40px;
  transition: all .2s $timing--butter;

  @include mq($bp__mobile-nav) {
    margin-left: 0;
  }

  @include mq($bp__half-nav) {
    width: 180px;
  }

}

.logo__letter {
  visibility: hidden;
  transform: translateY(-20px);
  transition: all .2s $timing--butter;

  &:nth-child(even) {
    transform: translateY(20px);
  }


  @include mq($bp__mobile-nav) {
    visibility: visible;
    transform: translateY(0);
    &:nth-child(even) {
      transform: translateY(0);
    }
  }
}

.nav__logo-icon {
  margin: 0;
  
  .hdr--overlay & {
    g {
      fill: $overlay-nav-link;
    }
  }
}



// --------------------------------------------
// Navigation Links
// --------------------------------------------

.nav {
  user-select: none;
  transition: all .2s $timing--butter;
  transform: translateY(0);

  @include mq($bp__full-nav) {
    user-select: all;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.nav__list {
  @include reset(ul);
  text-align: justify;
  font-size: 0.1px; // Hide whitespace between elements
  width: 100%;
  user-select: none; // Stops accidental text selection when clicking buttons
  position: absolute;
  left: -10px;
  width: auto;

  @include mq($bp__half-nav) {
    text-align: center;
    margin-left: 0;
  }
}

.nav__item {
  display: none;
  
  @include mq($bp__tablet) {
    display: inline-block;
  }

  &:first-child {
    display: inline-block;
  }
}

.nav__link {
  position: relative;
  display: block;
  padding: 17px $site-margin;
  @include sans(16px, bold);
  text-decoration: none;
  transition: .2s color $timing--butter;

  .hdr--overlay & {
    color: $overlay-nav-link;
  }

  &:first-of-type {
    margin-left: $site-margin * -1;
  }

  .nav__item:first-child & {
    margin-left: 0;
    padding-left: 40px;
    cursor: pointer;
  }
}

.nav__link__txt {
  @include underline-hover;
}

// --------------------------------------------
// Sub Nav
// --------------------------------------------

.sub-nav {
  position: absolute;
  width: inherit;
  padding: 0;
  z-index: $z-index--sub-nav;
  text-align: left;
  transition: all 0.2s $timing--simmer;
  display: block;
  right: 0;
  text-align: right;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);

  @include mq($bp__half-nav){
    text-align: left;
    right: auto;
  }

  .t-alt &,
  .t-alt--top & {
    background: 0;
    box-shadow: 0px 0px 15px 10px rgba($beige, 0.95);
  }

  .faq & {
    background: 0;
    box-shadow: 0px 0px 15px 10px rgba(#fff, 0.95);
  }

  .t-white & {
    background: 0;
    box-shadow: 0px 0px 15px 10px rgba(#fff, 0.95);
  }

  .dtl-page & {
    background: 0;
    box-shadow: 0px 0px 15px 10px rgba($bg--gray, 0.95);
  }

  .sub-nav-is-active & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.site-sub-nav__item {
  list-style: none;
}

.nav__item--about,
.nav__item--shop {
  .hdr--overlay & {
    display: none;
  }
}

.nav__link--sub {
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav__link--more {
  position: relative;
 &:after {
   content: "▾";
   display: block;
   @include absolute(21px 7px x x);
   color: inherit;
   font-size: 10px;
   transition: all 0.2s $timing--simmer;

   .sub-nav-is-active & {
    transform: rotate(180deg);
   }
 }

}

// --------------------------------------------
// Menu Button
// --------------------------------------------

.nav__menu-icon {
  position: absolute;
  top: 17px;
  left: 10px;
  display: block;
  height: 22px;
  width: 24px;
  @include svg("nav__menu");
  user-select: none;

  .hdr--overlay & {
    display: none;
  }
}
.nav__menu-btn {
  position: absolute;
  left: 0;
  user-select: none;
  padding-left: 40px;

  &:hover {
    cursor: pointer;
  }

  @include mq($bp__full-nav) {
    display: none;
  }
}


// --------------------------------------------
// Buy Button
// --------------------------------------------

.l-nav__buy {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: $site-margin * -1;
  padding-left: $site-margin;
}

.nav__buy {
  padding-left: 40px;
  outline: 0;
  .resp-txt {
    display: none;
    @include mq($bp__half-nav) {
      display: inline;
    }
  }
}

.nav__buy-icon {
  position: absolute;
  top: 18px;
  left: 10px;
  display: block;
  @include size(21px);

  background-image: svg-url("nav__cart.svg");
  background-size: contain;
  background-repeat: no-repeat;

  .hdr--overlay & {
    background-image: svg-url("nav__cart--overlay.svg");
  }
}

// --------------------------------------------
// Nav Overlay
// --------------------------------------------

.overlay {
  @include fixed(0 0 0 0);
  background-color: $blue-dark;
  z-index: $z-index--3;
  transform: translateY(-100%);
  overflow: hidden;
}

.overlay__content {
  // max-width: $site-max-width;
  margin: 0 auto 0;
  position: relative;
  @include size(100%);
}

.overlay--close {
  @include absolute(0 x x 0);
  color: white;
  padding: 25px 20px 25px 50px;
  @include sans(16px, bold);
  text-decoration: none;
  opacity: 0;
  z-index: $z-index--3;
}

.nav__close-icon {
  position: absolute;
  top: 26px;
  left: 27px;
  height: 16px;
  width: 16px;
  @include svg("nav__close");
  user-select: none;
}

.l-site-nav {
  @include absolute(0 0 0 0);
  @include size(100%);
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding: 84px 36px 36px;

  @include mq($bp__tablet) {
    width: 50%;
    right: auto;
  }

  .nav-is-animating & {
    overflow: hidden;
  }
}

.site-nav__list {
  list-style: none;
  padding: 0;

  @include mq($bp__tablet) {
    text-align: center;
  }
}

.site-nav__list-item {
  @include increment-delay($offset: .08s, $count: 4);
  opacity: 0;
  transform: translateY(-15px);
  transition: all 0.2s $timing--butter;

  .site-nav-in-view & {
    opacity: 1;
    transform: translateY(0);
  }
}

.site-nav__link {
  @include serif(48px);
  color: $blue;
  text-decoration: none;
  transition: color 0.2s $timing--butter;

  .no-touchevents &:hover {
    color: $faint-gray;
  }

  @include mq($bp__tablet) {
    font-size: 96px;
  }
}

.site-nav__txt {
  @include underline-hover(-6px, 2px, $overlay-nav-link);
}

.site-sub-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  &:before {
    @include pseudo;
    display: none;
    @include absolute(0 x x 50%);
    height: 1px;
    width: 0;
    background-color: rgba(255, 255, 255, 0.3);
    transform-origin: center;
    transition: all 0.2s $timing--butter;

    @include mq($bp__tablet) {
      display: block;
    }

    .site-nav-in-view & {
      width: 100%;
      left: 0;
    }
  }

  @include mq($bp__tablet) {
    flex-wrap: nowrap;
    margin-top: 48px;
  }
}

.site-sub-nav__item {
  padding-top: 10px;
  margin-top: 24px;
  position: relative;

  &:first-child {
    &:after {
      @include pseudo;
      height: 0;
      display: none;
      @include absolute(50% 0 x x);
      width: 1px;
      background-color: rgba(255, 255, 255, 0.3);
      transition: all 0.2s $timing--butter;

      @include mq($bp__tablet) {
        display: block;
      }

      .site-nav-in-view & {
        height: 100%;
        top: 0;
      }
    }
  }
}

.site-sub-nav__list {
  list-style: none;
  padding: 0;


  @include mq($bp__tablet) {
    text-align: center;
    margin: 36px 0;
  }
}

.site-sub-nav__list-item {
  @include increment-delay($offset: .08s, $count: 4);
  opacity: 0;
  transform: translateY(-8px);
  transition: all 0.2s $timing--butter;

  .site-nav-in-view & {
    opacity: 1;
    transform: translateY(0);
  }
}

.site-sub-nav__item {
  flex: 1 0 0;
  line-height: 2;
  flex-basis: 100%;

  @include mq($bp__tablet) {
    flex-basis: 50%;
  }
}

.site-sub-nav__h {
  color: $red;
  @include sans(18px, "bold");
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 8px;
  transition: color 0.2s $timing--butter;

  .cta__item:hover & {
    color: $blue;
  }
}

.site-sub-nav__p {
  color: $overlay-nav-link;
  @include sans(18px);
  margin-top: 0;
  margin-bottom: 8px;
  opacity: 0;
  transition: opacity 0.2s $timing--butter;

  .cta__item:hover & {
    opacity: 1;
  }
}

.site-sub-nav__link {
  @include sans(16px, "bold");
  text-decoration: none;
  color: $overlay-nav-link;
}

.site-sub-nav__contact-link {
  @include sans(21px);
  color: $overlay-nav-link;
  text-decoration: none;
}

.site-contact {
  margin-top: 64px;
  margin-bottom: 64px;
}

.l-cta {
  width: 50%;
  @include absolute(0 0 0 x);
  display: none;

  @include mq($bp__tablet) {
    display: flex;
    flex-wrap: wrap;
  }
}

.cta__item {
  flex-basis: 50%;
  height: 50%;
  position: relative;
  background-size: cover;
  background-position: center center;
  opacity: 0;
  transition: opacity 0.4s $timing--simmer;
  @include increment-delay($offset: .08s, $count: 3);
  background-repeat: no-repeat;
  background-color: $overlay-nav-link;
  position: relative;

  .cta-in-view & {
    opacity: 1;
  }

  &:first-child {
    background-color: $blue;
    flex-basis: 100%;
  }

  &:nth-child(2),
  &:nth-child(3) {
    background-size: 80%;
  }
}

.cta__link {
  display: block;
  @include size(100%);
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &:after {
    @include pseudo;
    @include absolute(0 0 0 0);
    @include size(100%);
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.2s $timing--butter;

    .cta__item:hover & {
      opacity: 1;
    }
  }

  .cta__item:hover & {
    color: $overlay-nav-link;
  }
}

.cta__content {
  padding: 24px;
  @include absolute(0 x x 0);
  z-index: $z-index--3;
}