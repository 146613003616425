// =============================================

//  Fonts

// =============================================

// Mixin: Font Face
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
//
// FOR: Repeating the tough-to-recall font-face calls
//      and reducing repetition of paths/file names
//
// REF: https://css-tricks.com/snippets/css/using-font-face/

@mixin font-face(
  $family-name,
  $file-name,
  $folder-name,
  $font-weight: normal,
  $font-style: normal
  ){
    @font-face {
      $font-path: $theme-path + "/static/fonts/" + $folder-name + "/#{$file-name}";
      font-family: "#{$family-name}";
      src: url($font-path + '.eot'); /* IE9 Compat Modes */
      src: local('☺'),
         url($font-path + '.eot?iefix') format('eot'), /* IE6-IE8 */
         url($font-path + '.woff') format('woff'),  /* Pretty Modern Browsers */
         url($font-path + '.svg') format('svg'), /* Legacy iOS */
         local('☺');
      font-weight: $font-weight;
      font-style: $font-style;
  }
}


//
// Eksell
//

@include font-face(
  $family-name: Eksell Large,
  $file-name: EksellDisplayWeb-Large,
  $folder-name: eksell
);

@include font-face(
  $family-name: Eksell Medium,
  $file-name: EksellDisplayWeb-Medium,
  $folder-name: eksell
);

@include font-face(
  $family-name: Eksell Small,
  $file-name: EksellDisplayWeb-Small,
  $folder-name: eksell
);


//
// Edmond
//

@include font-face(
  $family-name: Edmond,
  $font-weight: 300,
  $file-name: edmondsans-regular-webfont,
  $folder-name: edmond
);

@include font-face(
  $family-name: Edmond,
  $font-weight: 500,
  $file-name: edmondsans-medium-webfont,
  $folder-name: edmond
);

@include font-face(
  $family-name: Edmond,
  $font-weight: 700,
  $file-name: edmondsans-bold-webfont,
  $folder-name: edmond
);
