// =============================================

// Registration Page

// =============================================



// --------------------------------------------
// Header Type
// --------------------------------------------

.reg__hgroup {
  text-align: center;
  margin-bottom: 80px;
}

.reg__h1 {
  @include serif(100px, .8);
  color: $blue;
  margin-bottom: 8px;
  @include mq(600px) {
    font-size: 145px;
  }
}

.reg__h2 {
  @include serif(35px);
  color: $blue-dk;
  margin-top: 0;
  @include mq(600px) {
    font-size: 55px;
  }

  .register-page & .br {
    display: block;
    @include mq(480px) {
      display: inline;
    }
  }

  .register-received-page & .br {
    @include mq(900px) {
      display: block;
    }
  }

}



// --------------------------------------------
// Header Type
// --------------------------------------------

.reg__intro-h {
  @include sans(26px, bold);
  color: $red;
  text-align: center;
  margin-bottom: 20px;
}

.reg__intro-list {
  @include sans(18px, medium);
  line-height: 1.3;

  max-width: 400px;
  margin: 0 auto 60px;
  padding-left: 30px;
}

.reg__intro-item {
  margin-bottom: 16px;
}



// --------------------------------------------
// Key Illustration
// --------------------------------------------

.reg__key-fig {
  margin-bottom: 60px;
  text-align: center;
}

.reg__key-caption {
  @include sans(14px, bold);
  margin-top: 10px;
}



// --------------------------------------------
// Form
// --------------------------------------------

.reg__form {
  max-width: 500px;
  margin: auto;
  text-align: center;
}

.reg__input {
  @include reset(input);

  display: block;
  width: 100%;
  border-bottom: 2px solid $blue-dk;
  margin-bottom: 12px;

  @include sans(32px, regular);
  text-align: center;
  color: $blue;
}

.reg__key-input {
  font-size: 40px;
}

.reg__label {
  display: block;
  margin-bottom: 50px;

  @include sans(14px, bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $blue-dk;
}

.reg__form-btn {
  @include reset(button);

  position: relative;
  border: 3px solid $red;
  padding: 12px;
  height: 125px;
  width: 125px;
  border-radius: 100%;

  @include serif(24px);
  color: $red;

  transition: .15s all $timing--butter;

  &:hover,
  &:focus {
    color: $bg;
    background-color: $red;
  }

}
