// =============================================

// Project Mixins

// =============================================


// Strip Units
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Removing the unit from a number and returning the raw value
// REF: https://github.com/nex3/sass/issues/533
// REF: http://stackoverflow.com/questions/12328259/how-do-you-strip-the-unit-from-any-number-in-sass/12335841#12335841
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
 }


// Image Url
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: getting link to img folder
// NOTE: your filname may need a directory
// USE: @include svg(dir/filename);

@function image-url($imageName) {
  @return url($theme-path + 'static/img/' + $imageName);
}


// SVG Url
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: getting link to img folder
// NOTE: your filname may need a directory
// USE: @include svg(dir/filename);

@function svg-url($imageName) {
  @return url($theme-path + 'static/svg/' + $imageName);
}


// Clearfix
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}



// SVG Fallback Pattern
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Setting PNG fallbacks for SVG background images
// WHY: Saves time and typing and is guaranteed to work across devices
// NOTE 1: This uses multiple background images as a proxy for SVG support
// NOTE 2: You still need to set the display (inline-block or block)
// and a height/width for the container
// NOTE 3: "image-url" is a helper that automatically creates a relative link to the _img folder
// from wherever it's called. This function is built-in to Compass. It's rare that you'll need
// change the base folder (_img) but it can be done in the config.rb file in the root
// REF: http://css-tricks.com/svg-fallbacks/
// REF: http://compass-style.org/reference/compass/helpers/urls
// USE: @include svg(imageName);

@mixin svg($image, $size: contain) {
  // Variables
  $svg: $image + ".svg";
  $png: $image + ".png";

  // Universal properties
  background-size: $size;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  // Fallback pattern
  background-image: svg-url($png);
  background-image: svg-url($svg), none;

  // Necessary override for some versions of
  // Android, which choke on the double bg image
  .no-svg & {
    background-image: svg-url($png);
  }

} // svg


// -----------------------------------
// Shorthand Generator ---------------
// -----------------------------------

@function auto($value) {
  @if $value == "a" {
    @return auto;
  } @else {
    @return $value;
  }
}

@mixin shorthander($position, $coordinates) {
  $position-prefix: "";

  @if $position == "margin"
   or $position == "padding" {
    $position-prefix: $position + "-";
  } @else {
    position: $position;
  }

  @if length($coordinates) == 1
  and $coordinates != "n"
  and $coordinates != "x"
  and $coordinates != "null" {
    #{$position-prefix}top: auto($coordinates);
    #{$position-prefix}right: auto($coordinates);
    #{$position-prefix}bottom: auto($coordinates);
    #{$position-prefix}left: auto($coordinates);
  }

  @if length($coordinates) == 2 {
    $coordinates-y: nth($coordinates, 1);
    $coordinates-x: nth($coordinates, 2);
    @if $coordinates-y != "n"
    and $coordinates-y != "x"
    and $coordinates-y != "null" {
      #{$position-prefix}top: auto($coordinates-y);
      #{$position-prefix}bottom: auto($coordinates-y);
    }
    @if $coordinates-x != "n"
    and $coordinates-x != "x"
    and $coordinates-x != "null" {
      #{$position-prefix}left: auto($coordinates-x);
      #{$position-prefix}right: auto($coordinates-x);
    }
  }

  @if length($coordinates) == 3 {
    $coordinates-y-top: nth($coordinates, 1);
    $coordinates-x: nth($coordinates, 2);
    $coordinates-y-bottom: nth($coordinates, 3);

    @if $coordinates-y-top != "n"
    and $coordinates-y-top != "x"
    and $coordinates-y-top != "null" {
      #{$position-prefix}top: auto($coordinates-y-top);
    }
    @if $coordinates-x != "n"
    and $coordinates-x != "x"
    and $coordinates-x != "null" {
      #{$position-prefix}right: auto($coordinates-x);
      #{$position-prefix}left: auto($coordinates-x);
    }
    @if $coordinates-y-bottom != "n"
    and $coordinates-y-bottom != "x"
    and $coordinates-y-bottom != "null" {
      #{$position-prefix}bottom: auto($coordinates-y-bottom);
    }
  }

  @if length($coordinates) == 4 {
    $coordinates-top: nth($coordinates, 1);
    $coordinates-right: nth($coordinates, 2);
    $coordinates-bottom: nth($coordinates, 3);
    $coordinates-left: nth($coordinates, 4);

    @if $coordinates-top != "n"
    and $coordinates-top != "x"
    and $coordinates-top != "null" {
      #{$position-prefix}top: auto($coordinates-top);
    }
    @if $coordinates-right != "n"
    and $coordinates-right != "x"
    and $coordinates-right != "null" {
      #{$position-prefix}right: auto($coordinates-right);
    }
    @if $coordinates-bottom != "n"
    and $coordinates-bottom != "x"
    and $coordinates-bottom != "null" {
      #{$position-prefix}bottom: auto($coordinates-bottom);
    }
    @if $coordinates-left != "n"
    and $coordinates-left != "x"
    and $coordinates-left != "null" {
      #{$position-prefix}left: auto($coordinates-left);
    }
  }
}

// -----------------------------------
// Shorthand Mixins ------------------
// -----------------------------------

@mixin absolute($coordinates) {
  @include shorthander(absolute, $coordinates);
}

@mixin relative($coordinates) {
  @include shorthander(relative, $coordinates);
}

@mixin fixed($coordinates) {
  @include shorthander(fixed, $coordinates);
}

@mixin padding($coordinates) {
  @include shorthander(padding, $coordinates);
}

@mixin margin($coordinates) {
  @include shorthander(margin, $coordinates);
}



// Absolute Positioning
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Quickly setting the top, right, left, and bottom positions of an absolutely positioned element
// WHY: It's verbose to write everytime in CSS
// USE: @include absolute(0 0 10px 0);
@mixin absolute-legacy($coordinates: n n n n) {

  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  position: absolute;

  @if $top == auto or $top == a {
    top: auto;
  }
  // Print nothing if we write an "n" or "none"
  @else if $top == n or $top == none {
  }
  @else {
    top: $top;
  }

  @if $right == auto or $right == a {
    right: auto;
  }
  // Print nothing if we write an "n" or "none"
  @else if $right == n or $right == none {
  }
  @else {
    right: $right;
  }

  @if $bottom == auto or $bottom == a {
    bottom: auto;
  }
  // Print nothing if we write an "n" or "none"
  @else if $bottom == n or $bottom == none{
  }
  @else {
    bottom: $bottom;
  }

  @if $left == auto or $left == a {
    left: auto;
  }
  // Print nothing if we write an "n" or "none"
  @else if $left == n or $left == none {
  }
  @else {
    left: $left;
  }
}

@mixin unabsolute {
  position: static;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

// Psuedo
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Quickly pasting essential and commonly used properties that make psuedo classes work
// WHY: Easier than remembering and writing each time. Should cut down on mistakes/debugging.
// USE: @include pseudo;
@mixin psuedo($coordinates: none) {
  content: " ";
  display: block;
  @if $coordinates != "none" {
    @include absolute($coordinates);
  }
}

@mixin pseudo($coordinates: none) {
  content: " ";
  display: block;
  @if $coordinates != "none" {
    @include absolute($coordinates);
  }
}


// Absolute Centering
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Centering (both horizontally & vertically)
// absolutely positioned items
// WHY: This does the math for you
// USE: @include absolute-center(n 22px);
@mixin absolute-center-legacy($dimensions) {
  // If only one value is supplied,
  // default to center the width
  @if length($dimensions) == 1 {
    $dimensions: $dimensions n;
  }

  $width: nth($dimensions, 1);
  $height: nth($dimensions, 2);

  $width--half: (-$width / 2);
  $height--half: (-$height / 2);


  position: absolute;

  @if $width != n {
    @if not(unitless($width)) {
      width: $width;
      transform: translateX(#{$width--half});
    }
    @if unitless($width) {
      width: $width + px;
      transform: translateX(#{$width--half} + px);
    }
    left: 50%;
  }

  @if $height != n {
    @if not(unitless($height)) {
      height: $height;
      transform: translateY(#{$height--half});
    }
    @if unitless($height) {
      height: $height + px;
      transform: translateY(#{$height--half} + px);
    }
    top: 50%;
  }
} // absolute-center


// Absolute Center
//-------------------------------
@mixin absolute-center($axis: "both") {
  position: absolute;
  @if ($axis == "both") {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($axis == "horizontal") {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($axis == "vertical") {
    top: 50%;
    transform: translate(0, -50%);
  }
}



// Size
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Applying width & height in a single declaration
// WHY: Faster. Less typing. Easier to deal with squares.
// NOTE: If you supply a single value, the result is a square
// REF: http://bourbon.io/docs/#size
// USE: @include size(50px 100px);

@mixin size($size) {
  @if length($size) == 1 {
    @if $size == auto {
      width:  $size;
      height: $size;
    }

    @else if unitless($size) {
      width:  $size + px;
      height: $size + px;
    }

    @else if not(unitless($size)) {
      width:  $size;
      height: $size;
    }
  }

  // Width x Height
  @if length($size) == 2 {
    $width:  nth($size, 1);
    $height: nth($size, 2);

    @if $width == auto {
      width: $width;
    }
    @else if not(unitless($width)) {
      width: $width;
    }
    @else if unitless($width) {
      width: $width + px;
    }

    @if $height == auto {
      height: $height;
    }
    @else if not(unitless($height)) {
      height: $height;
    }
    @else if unitless($height) {
      height: $height + px;
    }
  }
}





// Circle
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Making circles
// WHY: It's an obvious shorthand and
//      hey! it does the math for you
// HOW: Sets the size and cuts it in half
// USE: @include circle(10px);
@mixin circle($size: 10px) {
  @include size($size);
  border-radius: 100%;
}





// Layout Divider
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Setting a line automatically scales to the
//       height of the tallest box in the layout
// WHY: It's tricky to implement and not immediately
//       transparent when put into action
// USE: @include layout-divider(
//          $left: 70%,
//          $divider: 2px solid #e5e5e5;
//        );


@mixin layout-divider(
  // Layout position
  $top: 0,
  $right: n,
  $bottom: 0,
  $left: n,

  // Divider style
  $divider-width: 1px,
  $divider-style: solid,
  $divider-color: #ccc,
  $divider: $divider-width $divider-style $divider-color,

  // Applied to the before or after element?
  $pseudo-element: before ){

  $coordinates: $top $right $bottom $left;

  position: relative;

  @if $pseudo-element == "before" {
    &:before {
      @include pseudo($coordinates);
      border-left: $divider;
    }
  } @else {
    &:after {
      @include pseudo($coordinates);
      border-left: $divider;
    }
  }
}





// Reset Element
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Stripping the styles from default HTML elements
//      such as an input, ul, li, a, h1, h2, h3, etc.
// WHY: When using normalize, you might want to set
//      an element to a baseline with no style applied
// USE: @include reset(li);

.reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background-color: transparent;
}

@mixin reset($el: generic) {

  $tag: (
    generic: true,
    h: true,
    ol: true,
    ul: true,
    li: true,
    button: true,
    input: true
  );

  @if map-has-key($tag, $el) {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background-color: transparent;

    @if $el == "input"
     or $el == "button" {
      box-shadow: none;
      border-radius: 0;
      -webkit-appearance: none;
      &:focus {
        outline: 0;
      }
    } // input

    @if $el == "ul"
     or $el == "ol" {
      list-style: none;
    }

  } // map-has-key

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, the element `#{$el}` isn't recognized. "
        + "Please make sure it is defined in the `reset` mixin.";
  }

} // reset



//
// Media Grid Mixin
// ------
//
// 1. Fixes IE7 rounding error that would otherwise
// break the layout. For more, please see:
// http://johnalbin.github.io/fluid-grid-rounding-errors/
//
// 2. Enforces proper grid spacing by placing a break on
// the first item in each row. Count begins on first item
// in second row ($num plus one) and increments by the
// original number ($num) thereafter.
//

@mixin media-grid(
  $num: 2,
  $pad: 20px,
  $target:'.media-block') {

  // Variables
  $n-width: 100%/$num;
  $n: $num + 'n';
  $n-plus-one: $num + 1;

  $pad: $pad/2;

  // Styles
  margin-left: -#{$pad};
  margin-right: -#{$pad};
  @include clearfix;

  > #{$target} {
    float: left;
    padding-left: #{$pad};
    padding-right: #{$pad};
    width: $n-width;

    .ie7 & {
      width: floor($n-width); // [1]
    }

    &:nth-of-type(#{$n}+#{$n-plus-one}) {
      clear: left; // [2]
    }
  } // target
} //media-grid


//
// Media Grid Mixin - Neue
// ------
//

//Upbase variables
$support-ie7: false !default;
$ie-conditional-classes: true !default;
$grid-target: '*' !default;

@mixin grid(
  $items: 2,
  $gutter: 20px,
  $target: $grid-target ) {

  //Variables
  $list-length: length($items);
  $items-per-row: null; //determined later in mixin, globally defined here
  $gutter: $gutter/2;

  //Parent container styles
  @include clearfix;
  margin-left: -#{$gutter};
  margin-right: -#{$gutter};


  //Child grid styles
  //If length of items list is gt 1, means multi-column widths provided
  @if $list-length > 1 {
    $items-per-row: $list-length;
    @for $i from 1 through $list-length {
      $item-size: nth($items, $i);
      > #{$target}:nth-child(#{$i}) {
        float: left;
        padding-left: #{$gutter};
        padding-right: #{$gutter};
        width: $item-size;
      }
    }
  } @elseif $list-length == 1 {
    //If length of list is 1, use evenly-sized items
    $items-per-row: $items;
    $item-size: 100% / $items;
    > #{$target} {
      float: left;
      padding-left: #{$gutter};
      padding-right: #{$gutter};
      width: $item-size;
    }
  }

  //Clears all clears; when used across media queries, for example
  & > #{$target}:nth-child(1n) {
    clear: none;
  }

  //Clear first item in each row
  $items-per-row-n: $items-per-row + 'n';
  & > #{$target}:nth-child( #{$items-per-row-n} + 1 ) {
    clear: both;
  }

}// eo grid




// The Media Query Mixin
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Write less verbose Media Queries with variables
// or using straight figures (eg 320px)
// WHY: Faster to write. More flexible. Lets you write
// media query variables on the fly.
// USE: @include mq(320px, 798px);
// USE: @include mq($tablet, $desktop);
// USE: @include mq(max, $desktop);

@mixin mq($bp-1, $bp-2: null) {
  @if $bp-1 == "max" {
    @media (max-width:$bp-2 - 1) { @content; }
  }

  @elseif $bp-2 == "max" {
    @media (max-width:$bp-1 - 1) { @content; }
  }

  @elseif $bp-2 == null {
    @media (min-width: $bp-1) { @content; }
  } //single bp

  @else {
    @media (min-width: $bp-1) and (max-width:$bp-2 - 1) { @content; }
  } //bracketed breakpoint
}



// Child Order
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

// An all-in-one mixin for setting the order of
// flexbox items. Apply to parent container.
//
// USE: @include child-order(1 4 5 2 3);

@mixin child-order($order: 1 2 3, $element: '> *') {
  // children outside not included in order are sent to
  // the back of the line
  #{$element} {
    order: length($order) + 1;
  }
  @for $i from 1 through length($order) {
    #{$element}:nth-of-type(#{nth($order, $i)}) {
      order: $i;
    }
  }
}




// Triangles
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

// This is the awesome triangle mixin from bourbon. More info here: http://bourbon.io/docs/#triangle
// - The $size argument can take one or two values—width height.
// - The $color argument can take one or two values—foreground-color background-color.
// - $direction: up, down, left, right, up-right, up-left, down-right, down-left

// FOR: Creates a visual triangle. Mixin takes ($size, $color, $direction)
// WHY: Because triangles.
// USE: @include triangle(12px 6px, gray lavender, up-left);

@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}



// Faux Background
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Creating the appearance that the site background color spans
// from one section to the next before cutting itself off
// WHY: It's a common pattern with some complication.
// USE: @include faux-background(
//        $height: 100px,
//        $placement: "top"
//        );

@mixin faux-background(
  $height: 100px,
  $placement: "top",
  $coordinates: false) {

  z-index: $z-index--faux-background;
  @if $coordinates {
    @include absolute($coordinates);
  } @else {
    @if $placement == "top" {
      @include absolute(0 0 n 0);
    } @else {
      @include absolute(n 0 0 0);
    } // placement
  } // coordinates
  height: $height;
  background-color: $bg;
}

@mixin faux-background--pseudo(
  $height: 100px,
  $placement: "top",
  $coordinates: false,
  $pseudo-element: "before") {

  position: relative;

  @if $pseudo-element == "before" {
    &:before {
      @include pseudo;
      @include faux-background(
        $height,
        $placement,
        $coordinates: false
        );
    }
  } @else {
    &:after {
      @include pseudo;
      @include faux-background(
        $height,
        $placement,
        $coordinates: false
        );
    }
  }
}

// Grid Static
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// Some style to see it in action

// .l-static {
//   @include grid-static(300px, 40px);
// }

// .ui-block-1,
// .ui-block-2 {
//   position: relative;
//   height: 200px;
//   margin-bottom: 10px;
//   background-color: #bada55;
// }

// .ui-block-2 {
//   background-color: #acce45;
// }

// p {
//   color: #ffffff;
//   font-family: sans-serif;
//   font-size: 72px;
//   line-height: 1;
//   margin: 0;
//   padding: 0;
//   padding: 50px;
//   text-align: center;
// }

@mixin grid-static(
  $width: 300px,
  $margin: 40px,
  $static-col: right) {
  // Variables
  $total: $width + $margin;

  // If the right column has a static width
  @if $static-col == right {
    > .l-block__left {
      width: 100%;
      float: left;
      margin-right: -$total;
      > .l-block__inner {
        margin-right: $total;
      }
    }

    > .l-block__static-right {
      width: $width;
      float: right;
    }
  } // right

  // If the left column has a static width
  @else {
    > .l-block__static-left {
      width: $width;
      float: left;
    }
    > .l-block__right {
      width: 100%;
      float: right;
      margin-left: -$total;
      > .l-block__inner {
        margin-left: $total;
      }
    }
  } //else

  @include clearfix;
}

// Border mixin
// usage: @include border(1px 0 1px 0, #000, dotted);

@mixin border(
  $coordinates: 0 0 0 0,
  $color: #000000,
  $style: solid ) {

  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  @if not(unitless($top)) {
    border-top: $top $style $color;
  }
  @if not(unitless($right)) {
    border-right: $right $style $color;
  }
  @if not(unitless($bottom)) {
    border-bottom: $bottom $style $color;
  }
  @if not(unitless($left)) {
    border-left: $left $style $color;
  }
}


// Increment Delay
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Adding an incrementing animation or transition delay
//      to multiple elements. Helps with boop-boop-boop animation.
// WHY: It's repetitive to write. Easy to mess up.
//      And this makes it easier to test different timing.
// USE: @include increment-delay(
//        $offset: .05s,
//        $count: 3
//        );
@mixin increment-delay(
  $offset: .05s,
  $count: 10,
  $delay: 0,
  $type: transition) {

  // #{$type}-delay: ($count * $offset) + $delay;

  @for $i from 1 through $count {
    $time: ($i * $offset) + $delay;

    &:nth-of-type(#{$i}) {
      #{$type}-delay: $time;
    }
  }
}



@mixin underline-hover(
  $offset: -6px,
  $weight: 2px,
  $color: $blue-dk) {

  position: relative;

  &:after {
    @include psuedo(n n $offset 50%);
    width: 0;
    height: $weight;
    background-color: $color;
    transform: translateZ(0);
    transition: all .2s $timing;
  }

  .no-touchevents &:hover:after,
  .no-touchevents a:hover &:after {
    width: 100%;
    left: 0;
  }
}


@mixin arrow-hover(
  $offset-left: 96%,
  $arrow-offset: 12px) {
  padding-right: $arrow-offset;
  
  // arrow
  &:after {
    content: "›";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s $timing--butter;
  }
  
  &:hover:after {
    .no-touchevents & {
      right: -6px;
    }
  }

  // line
  &:before {
    @include psuedo(0 n n 90%);
    content: "—";
    transform-origin: center center;
    height: 1px;
    width: 0;
    overflow: hidden;
    transition: all .3s $timing--butter;
  }

  &:hover:before {
    .no-touchevents & {
      left: $offset-left;
      width: 10px;
      overflow: visible;
    }
  }
}


