// =============================================

// Homepage

// =============================================

.content--hp {
  > * + * {
    margin-top: 140px;
    @include mq($bp__phablet) {
      margin-top: 250px;
    }
    @include mq($bp__desktop) {
      margin-top: 200px;
    }
  }
}

// --------------------------------------------
// Hero
// --------------------------------------------

.hp__section--hero {
}

// Pinstripe background
// - Isolated for purposes of animation
.hp__section__bg--hero {
  @include absolute(450px 0 -400px 0);
  width: 115px;
  margin: auto;

  // Background matches hp__section--features
  @include mq($bp__tablet) {
    background: transparent svg-url("pinstripes--mobile.svg") 50% 50% repeat-y;
  }
  @include mq($bp__desktop) {
    background-image: svg-url("pinstripes.svg");
  }
}

.hp-hero__hgroup {
  background-color: $bg;
  margin-bottom: 0;
  padding-bottom: 20px;
  text-align: center;

  @include mq($bp__tablet--sm) {
    margin-bottom: 40px;
  }
  @include mq($bp__tablet) {
    margin-bottom: 50px;
  }
}

.hp-hero__h {
  margin: 0 0 12px 0;
  @include serif(80px);
  color: $blue;
  // see animation below
  // with `Hero Animations`

  @include mq($bp__phone) {
    font-size: 90px;
  }
  @include mq($bp__phablet) {
    font-size: 110px;
    margin-bottom: 16px;
  }
  @include mq($bp__tablet--sm) {
    font-size: 140px;
    margin-bottom: 20px;
  }
  @include mq($bp__tablet) {
    font-size: 170px;
  }
}

.hp-hero__deck {
  @include sans(24px);
  color: $blue-dk;
  margin: 0 auto;
  // see animation below
  // with `Hero Animations`

  @include mq($bp__phone) {
    font-size: 26px;
  }
  @include mq($bp__phablet) {
    font-size: 28px;
  }
  @include mq($bp__tablet--sm) {
    font-size: 31px;
  }
  @include mq($bp__tablet) {
    font-size: 34px;
  }
}

// 
// Color Switcher
// 

.hp-hero__cs-mod {
  position: relative;
}

.hp-hero__cs-mod-container {
  position: relative;
  padding-top: 68.2%;
}

.hp-hero__cs-img {
  @include absolute(0 0 x 0);
  opacity: 0;
}

.hp-hero__cs-img--active {
  opacity: 1;
}

.color-switcher {
  max-width: 170px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 0;
  
  @include mq($bp__tablet) {
    @include absolute(x x 30px 60px);
  }
}

.color-switcher__list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.color-switcher__link {
  display: inline-block;
  @include size(30px);
  border: 3px solid transparent;
  border-radius: 100%;
  position: relative;

  &:after {
    content: "";
    box-sizing: border-box;
    display: block;
    @include size(20px);
    @include absolute(2px x x 2px);
    border-radius: 100%;
    opacity: 0;
    transform: scale(0, 0);
    transition: all 0.2s $timing--butter;

    .color-switcher--active & {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  &--navy {
    border-color: $navy;

    &:after {
      background-color: $navy;
    }
  }

  &--tomato {
    border-color: $red;

    &:after {
      background-color: $red;
    }
  }

  &--steel {
    border-color: $gray-dk;

    &:after {
      background-color: $gray-dk;
    }
  }
}

.color-switcher__list-item {
  display: inline-block;
}

.color-switcher__text {
  @include sans(16px, "med");
  text-align: center;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 12px;
}

//
// Hero Image
//

.hp-hero__img-btn-mod {
  position: relative;
}

.hp-hero__figure {
  width: 90%;
  margin: 0 auto;
  // see animation below
  // with `Hero Animations`

  @include mq($bp__tablet--sm) {
    width: 75%;
    max-width: 830px;
  }
}


.hp-hero__btn-mod {
  @include clearfix;
  margin: auto;
  max-width: 325px;

  @include mq($bp__phablet) {
    max-width: none;
  }

  @include mq(500px) {
    max-width: 500px;
  }
}


//
// Buy Button
//

.hp-hero__buy-btn {
  // `.buy-btn` applied in markup
  float: left;
  left: -10px;
  border-radius: 100%;
  @include size(140px);
  // see animation below
  // with `Hero Animations`

  @include mq(400px) {
    left: 0;
  }

  @include mq($bp__phablet) {
    @include size(200px);
  }

  @include mq(485px) {
    left: 4.5%; // matches .hp-hero__video-btn
  }

  @include mq(700px) {
    float: none;
    position: absolute;
    left: 30px;
    top: -20px;
    border-radius: 0;
    @include size($buy-btn-size--md);
  }

  @include mq($bp__desktop) {
    @include size($buy-btn-size--lg);
  }

  .buy-btn__inner {
    @include mq(max, $bp__phablet) {
      top: 18%;
      left: 74%;
    }
    @include mq($bp__phablet, 700px) {
      top: 15%;
      left: 73%;
    }
  }
}


//
// Video Button
//

.hp-hero__video-btn,
.landing__video-btn {
  display: block;
  position: relative;
  float: right;
  right: -10px;

  @include circle(140px);
  padding: 20px;
  border: 3px solid $red;

  color: $blue-dk;
  text-align: center;
  text-decoration: none;
  outline: 0;

  @include mq(400px) {
    right: 0;
  }

  @include mq($bp__phablet) {
    position: absolute;
    float: none;

    @include size(200px);
    border-width: 4px;
  }

  @include mq(485px) {
    right: 4.5%; // matches .hp-hero__buy-btn
  }

  @include mq(700px) {
    bottom: 30px;
  }

  @include mq($bp__desktop) {
    right: 5%;
    bottom: 10%;
  }

  @include mq($bp__desktop--xl) {
    right: 7%;
    bottom: 12.5%;
  }

  @include mq($site-max-width) {
    right: 9%;
    bottom: 14%;
  }

}

.hp-hero__video-btn__h {
  @include serif(30px);

  @include absolute-center(horizontal);
  bottom: 0;
}

.hp-hero__video-btn__play-btn {
  display: block;
  position: absolute;
  top: 36%;
  left: 54%; // visually center triangle
  transform: translate(-50%, -50%);
  width: 35px;

  @include mq($bp__phablet) {
    width: 45px;
    top: 45%;
  }

  .play-btn path {
    fill: transparent;
    transition: fill .2s $timing--butter;
  }
  a:hover & .play-btn path {
    fill: $red;
  }
}

//
// Rotated Video Text
//

@mixin rotated-text(
  $num-letters: 100,
  $initial-number: 0,
  $initial-value: 0,
  $angle-offset: 7.5deg) {

  //$angle-per-char: floor($angle-span / $num-letters);

  @for $i from 1 through $num-letters {
    .char-#{$initial-number + $i} {
      transform: rotate($initial-value + ($angle-offset * $i));
    }
  }
}

.char {
  height: 84px;
  position: absolute;
  width: 12px;
  left: 0;
  top: 0;
  transform-origin: bottom center;
}

.hp-hero__video-btn__txt {
  display: none;
  position: absolute;
  top: 60px;
  left: 16px;
  transform: rotate(-70deg);

  @include sans(14px, med);
  text-transform: uppercase;

  .backgroundblendmode & {
    // mix-blend-mode: difference;
    // color: #fff;
  }

  @include mq($bp__phablet) {
    display: block;
  }

  .char-1 {
    transform: rotate(0deg);
  }
  @include rotated-text(6, 1);
  // W
  .char-8 {
    transform: rotate(54deg);
  }
  @include rotated-text(3, 8, 55.5);
  // M
  .char-12 {
    transform: rotate(88deg);
  }
  @include rotated-text(7, 12, 90);
}



//
// Hero Animations
//

@keyframes hero-h--in {
  0% {
    opacity: 0;
    transform: translate3d(0, 150px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-deck--in {
  0% {
    opacity: 0;
    transform: translate3d(0, 35px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-figure--in {
  0% {
    opacity: 0;
    transform: translate3d(0, 350px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-buy-btn--in {
  0% {
    opacity: 0;
    transform: rotate(45deg)
               translate3d(0, 100px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotate(45deg)
               translate3d(0, 0, 0);
  }
}

@keyframes hero-video-btn--in {
  0% {
    opacity: 0;
    transform: translate3d(100px, 100px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-bg--in {
  0% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Run hero animations only if ...
// - CSS animations are supported
// - Page is loaded
.cssanimations.page-loaded:not(.page-timeout),
.cssanimations.page-loaded:not(.landing-page) {
  .hp-hero__h {
    animation: hero-h--in 1s $timing--easeOutQuint;
    animation-delay: .1s;
    animation-fill-mode: forwards;
  }

  .hp-hero__deck {
    animation: hero-deck--in 1s $timing--easeOutQuint;
    animation-delay: .2s;
    animation-fill-mode: forwards;
  }

  .hp-hero__figure {
    animation: hero-figure--in 1.2s $timing--easeOutQuint;
    animation-fill-mode: forwards;
  }

  .hp-hero__buy-btn {
    animation: hero-buy-btn--in 1.2s $timing--easeOutQuint;
    animation-delay: .2s;
    animation-fill-mode: forwards;
  }

  .hp__section__bg--hero {
    animation: hero-bg--in 2s $timing--easeOutQuint;
    animation-fill-mode: forwards;
    animation-delay: .2s;
  }

  .hp-hero__video-btn {
    animation: hero-video-btn--in 1.2s $timing--easeOutQuint;
    animation-fill-mode: forwards;
    animation-delay: .2s;
  }
}

// Fallback in case ...
// - CSS animations aren't supported
// - Page takes too long to load
.hp-hero__h,
.hp-hero__deck,
.hp-hero__figure,
.hp-hero__buy-btn,
.hp__section__bg--hero,
.hp-hero__video-btn {
  opacity: 0;

  .page-timeout &,
  .no-cssanimations & {
    opacity: 1;
  }
}


// --------------------------------------------
// Features
// --------------------------------------------

//
// Layout
//

.l-hp__section--features {
  margin-top: 0;
  @include faux-background--pseudo(60px);
}

.hp__section--features {
  // Background matches hp__section--hero
  padding-top: 140px;

  @include mq($bp__tablet) {
  background: transparent svg-url("pinstripes--mobile.svg") 50% 50% repeat-y;
  }
  @include mq($bp__desktop) {
    background-image: svg-url("pinstripes.svg");
  }
}

.hp-features {
  > * + * {
    margin-top: 100px;
    @include mq($bp__phablet, $bp__tablet) {
      margin-top: 140px;
    }
  }
}

.hp-feature {
  position: relative;
  @include clearfix;
  text-align: center;
  @include mq($bp__tablet--sm) {
    text-align: left;
  }
}

$l-hp-feature--width: 530px;

.l-hp-feature--txt {
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;

  @include mq(400px) {
    width: 80%;
  }
  @include mq($bp__tablet--sm) {
    float: left;
    padding-left: 30px;
    width: 50%;
    max-width: $l-hp-feature--width;
  }
  @include mq($bp__tablet) {
    padding-left: 80px;
  }
  @include mq($bp__desktop) {
    float: none;
    padding-left: 110px;
    @include absolute-center(vertical);
    right: 0;
  }
}

.l-hp-feature--img {
  margin-bottom: 20px;

  @include mq($bp__tablet--sm) {
    float: left;
    margin-bottom: 0;
    width: 50%;
    max-width: $l-hp-feature--width;
  }

  @include mq($bp__tablet) {
    padding-right: 70px;
  }

  @include mq($bp__desktop) {
    padding-right: 90px;
  }

}

.hp-feature__h .resp-txt {
  display: block;

  @include mq($bp__tablet--sm) {
    display: inline;
  }
}

.hp-feature__img {
  display: block;
  margin: auto;

  .hp-feature--freedom & {
    width: 80%;
    @include mq($bp__tablet--sm) {
      width: 100%;
    }
  }
  .hp-feature--flexibility & {
    width: 100%;
  }
  .hp-feature--security & {
    width: 80%;
  }
}

.hp-feature--security {
  // Special styles because security
  // takes on an irregular circle shape

  .hp-feature__img {
    border-radius: 100%;
    max-width: 300px;
    @include mq($bp__tablet--sm) {
      width: 80%;
    }
    @include mq($bp__tablet) {
      max-width: none;
    }
  }

  .l-hp-feature--img {
    @include mq($bp__tablet--sm) {
      float: right;
      padding-right: 0;
    }
    @include mq($bp__tablet) {
      padding-left: 80px;
    }
    @include mq($bp__desktop) {
      padding-left: 90px;
    }
  }

  .l-hp-feature--txt {
    @include mq($bp__tablet--sm) {
      float: left;
      padding-left: 0;
      right: auto;
      left: 15px;
    }
    @include mq($bp__tablet) {
      padding-right: 70px;
    }
    @include mq($bp__desktop) {
      padding-right: 100px;
    }
  }
} // hp-feature--security

//
// Typography
//

.hp-feature__txt {
  margin-bottom: 21px;
}

.hp-feature__more {
  position: relative;
  display: inline-block;
  padding-right: 12px; // makes room for arrow

  @include sans(15px, bold);
  text-transform: uppercase;
  color: #999;
  letter-spacing: 1px;
  text-decoration: none;

  // arrow
  &:after {
    content: "›";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s $timing--butter;
  }

  &:hover:after {
    right: -6px;
  }

  // line
  &:before {
    @include psuedo(0 n n 90%);
    content: "—";
    transform-origin: center center;
    height: 1px;
    width: 0;
    overflow: hidden;
    transition: all .3s $timing--butter;
  }

  &:hover:before {
    left: 96%;
    width: 10px;
    overflow: visible;
  }
}


// --------------------------------------------
// Feature Shapes
// --------------------------------------------

.hp-feature-shape {
  display: block;
  color: $blue-dk;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  transition: opacity .2s linear;
  box-shadow: 5px 5px 0 #fff,
              -5px -5px 0 #fff;
  &:hover {
    cursor: pointer;
  }

  // Diagonal Shape Swash
  &:after {
    z-index: $z-index--shape-swash;
    @include pseudo(50% n n -60%);
    width: 225%;
    border-top: $shape-border;
    transform: rotate(45deg);

    @include mq($bp__tablet) {
      display: none;
    }
  } // after


  // Animate into view on scroll
  .no-touchevents & {
    opacity: 0;

    &.in-view {
      opacity: 1;
    }
  }

} // hp-feature-shape


//
// Circle
//
.hp-feature-shape--circle {
  // covers overhanging pinstripes
  position: relative;
  bottom: -15px;
  // centers box shadow
  box-shadow: 0 5px 0 #fff,
              0 -5px 0 #fff;

  .resp-txt {
    display: block;
  }
}

.hp-feature-shape__inner {
  pointer-events: none;
  user-select: none;
}

//
// Diamond
//

.hp-feature-shape--diamond:after {
  transform: rotate(90deg);
}

.hp-feature-shape--diamond:before {
  // Add background color
  // to cover diagonal swash
  @include pseudo(0);
  background-color: #fff;
}

.hp-feature-shape__txt--top--diamond {
  position: relative;
  top: 20px;
  transition: all .2s $timing--butter;
  a:hover & {
    transform: translateY(5px);
  }
}

.hp-feature-shape__txt--btm--diamond {
  position: relative;
  bottom: 20px;
  transition: all .2s $timing--butter;
  a:hover & {
    transform: translateY(-5px);
  }
}

.hp-feature-shape__h {
  @include serif(26px);
  margin: 28px 0 10px 0;
  &--diamond {
    margin-bottom: 30px;
  }

  @include mq($bp__tablet--sm) {
    font-size: 32px;
  }
}

.hp-feature-shape__txt {
  @include sans(14px, bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;

  @include mq($bp__tablet--sm) {
    font-size: 16px;
  }
}



// --------------------------------------------
// Lifestyle: Grid
// --------------------------------------------

//
// Layout
//

.hp__section--life {
  background: transparent svg-url("pinstripes--mobile.svg") 4% 50% repeat-y;
  @include mq(400px) {
    background-position: 8% 50%;
  }
  @include mq($bp__phablet) {
    background-image: svg-url("pinstripes.svg");
    background-position: 6.5% 50%;
  }
  @include mq($bp__desktop) {
    background-position: 140px 50%;
  }
}

.hp-life__grid {
  @include clearfix;
}

.hp-life__grid--top {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
}

//
// Lifestyle Shape
//

.hp-life {
  position: relative;
}

.hp-life__shape-bg--circle {
  top: -110px;
  left: -180px;
  width: 33%;
  min-width: 335px;

  @include mq($bp__tablet--sm) {
    top: -160px;
    min-width: 375px;
  }

  @include mq($bp__desktop) {
    top: -200px;
    min-width: 325px;
  }
}

.hp-life__shape-bg--square {
  z-index: $z-index--hp-life__shape-bg;
  right: -100px;
  width: 53%;
  min-width: 250px;
  top: 26%;

  @include mq($bp__tablet--sm) {
    top: 25%;
  }

  @include mq($bp__desktop) {
    margin-top: -60px;
    right: -100px;
    width: 33%;
  }
}


//
// Lifestyle Photos & Text
//

.hp-life__hgroup {
  align-self: flex-start;
  max-width: 600px;

  background-color: #fff;
  padding-right: 10px;
  padding-bottom: 40px;

  @include mq($bp__tablet--sm) {
    width: 65%;
  }

  @include mq($bp__desktop) {
    width: 35%;
    float: left;
    padding-left: 15px; // aligns left edge with l-hp-feature--txt
    padding-right: 20px;
    padding-bottom: 60px;
  }
}

.hp-life__h {
  position: relative;
  z-index: $z-index--shape__h;
}

.hp-life__h__mobile {
  @include mq($bp__desktop) {
    display: none;
  }
}

.hp-life__h__desktop {
  display: none;
  @include mq($bp__desktop) {
    display: block;
  }
}

// Image animation

.hp-life__img {
  transition: .4s all $timing--butter;

  .l-hp-life__img--r2 &,
  .l-hp-life__img--r3 & {
    transition-delay: .25s;
  }

  .no-touchevents & {
    opacity: 0;
    transform: translate3d(0, 35px, 0);

    &.in-view {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  } // no-touchevents
} // hp-life__img


// Image spacing

.l-hp-life__img--l1,
.l-hp-life__img--l2 {
  padding-right: 10px;
  @include mq($bp__desktop) {
    padding-right: 20px;
  }
}

.l-hp-life__img {
  margin-bottom: 10px;
  @include mq($bp__desktop) {
    margin-bottom: 20px;
  }
}


// Left Images

.l-hp-life__img--l1 {
  float: left;
  width: 35%;
}

.l-hp-life__img--l2 {
  float: left;
  width: 60%;
}


// Right Images

.l-hp-life__img--r1 {
  float: right;
  width: 65%;

  @include mq($bp__tablet--sm) {
    padding-right: 10%;
  }

  @include mq($bp__desktop) {
    margin-top: 80px;
    padding-right: 15%;
  }
}

.l-hp-life__img--r2 {
  @include mq($bp__desktop) {
    float: right;
    width: 65%;
  }
}

.l-hp-life__img--r3 {
  float: left;
  width: 40%;
  @include mq($bp__tablet--sm) {
    width: 30%;
  }
}


// Mobile Order

@include mq(max, $bp__desktop) {
  .hp-life__hgroup {
    order: 1;
  }
  .l-hp-life__img--l1 {
    order: 2;
  }
  .l-hp-life__img--r1 {
    order: 3;
  }
  .l-hp-life__img--r2 {
    order: 4;
  }
}



// --------------------------------------------
// Lifestyle: Words
// --------------------------------------------

.hp-life__words {
  @include clearfix;
}

//
// Verbatim
//

.verbatim--hp {
  margin: 100px 0 160px;
  @include clearfix;

  @include mq($bp__phablet) {
    margin: 100px 20px 160px;
  }

  @include mq($bp__tablet) {
    margin: 100px 80px;
  }

  .verbatim__item {
    margin: 0;
    padding: 20px 0;
    background-color: #fff;

    @include mq($bp__tablet) {
      float: right;
      width: 75%;
    }
  }

  .verbatim__h {
    margin-top: 0;
  }

  .verbatim__shape-bg--triangle {
    top: -11%;
    @include mq($bp__phone) {
      top: -40px;
    }
    @include mq($bp__phablet) {
      top: -60px;
    }
  }
}



//
// No More What-Ifs
//

.hp-what-ifs {
  border: $shape-border;
  width: 155px;
  @include margin(n n 200px 45%);
  clear: both;

  text-align: center;
  background-color: #fff;
  box-shadow: 5px 5px 0 #fff,
              -5px -5px 0 #fff;

  @include mq($bp__phablet) {
    width: 195px;
  }

  @include mq($bp__tablet) {
    margin-left: 2px;
  }

  @include mq($bp__desktop) {
    margin-left: 94px;
  }

  @include mq($bp__desktop--xl) {
    margin-top: -100px;
  }
}

.hp-what-ifs__inner {
  padding: 24px 17px 32px;
}

.hp-what-if__list {
  @include reset(ul);
}

.hp-what-if__item {
  position: relative;

  & + & {
    padding-top: 32px;
  }

  // the `x` between items
  & + &:before {
    @include pseudo;
    @include absolute-center(horizontal);
    top: 7px;
    @include size(12px);
    @include svg("x");
  }
}

.hp-what-if__h {
  @include serif(24px);
  margin: 0 0 20px 0;

  @include mq($bp__phablet) {
    font-size: 28px;
  }
}

.hp-what-if__txt {
  @include sans(14px, bold);
  margin: 0;
  position: relative;
  display: inline-block;

  @include mq($bp__phablet) {
    font-size: 16px;
  }

  // the strikethrough on each item
  &:after {
    @include pseudo(n -5px 8px);
    width: 0;
    height: 3px;
    background-color: $blue;
    opacity: .7;

    transform: translateZ(0);
    transition: all .2s $timing;
  }

  .backgroundblendmode &:after {
    //mix-blend-mode: multiply;
  }

  .in-view &:after {
    width: 100%;
    left: 0;
  }
}


// --------------------------------------------
// Homepage Finale
// --------------------------------------------

.l-hp__section--finale {
  position: relative;
  margin-top: 0;

  &:before {
    @extend .site-width;
    @extend .hp__section--life;
    @include pseudo(0 0 n 0);
    height: 20px;
    z-index: $z-index--pinstripe;

    @include mq($bp__tablet--sm) {
      height: 50px;
    }
  }
}

.hp__ftr {
  position: relative;
}

// --------------------------------------------
// Gallery
// --------------------------------------------

.hp__slide-gallery {
  position: relative;
}


// --------------------------------------------
// Pick Color
// --------------------------------------------

.pick-color {
  // extends diamond in html
  @include size(190px);
  position: absolute;
  top: -200px;
  right: 70px;
  z-index: $z-index--pick-color;

  display: none;
  @include mq($bp__tablet--sm) {
    display: block;
  }

  .diamond__inner:before,
  .diamond__inner:after {
    display: none;
  }
}

.price {
  @include absolute(x -40px -75px x);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: $blue-dark;
  z-index: $z-index--1;
}

.price__txt {
  @include sans(16px, bold);
  line-height: 20px;
  color: $faint-gray;
}

.pick-color__h {
  position: relative;
  margin: 13px 0 39px 0;

  @include serif(60px);

  &:before,
  &:after  {
    @include psuedo(n 0 n 0);
    transition: all .3s $timing--butter;
    border-bottom: 3px solid $red;
  }
  &:before {
    bottom: -17px;
  }
  &:after {
    bottom: -25px;

  }
  a:hover &:after {
    left: -6px;
    right: 6px;
  }
  a:hover &:before {
    left: 6px;
    right: -6px;
  }
}

.pick-color__txt {
  @include sans(16px, medium);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}



// --------------------------------------------
// Triple Buy Module
// --------------------------------------------

// Mobile Layout
// - Pretty complex so quaratined
//   inside of its own max media query

@include mq(max, $bp__tablet--sm) {

  .tri-buy__color-circle {
    position: absolute;
    right: -40px;
    bottom: 10px;

    transform: rotate(180deg);
    width: 63%;

    .tri-buy__item:nth-child(even) & {
      right: auto;
      left: -40px;
    }
  }

  .tri-buy__link {
    width: 60%;
    float: left;
    .tri-buy__item:nth-child(even) & {
      float: right;
    }
  }
}


.tri-buy {
  @include reset(ul);
  margin-top: 10px;

  @include mq($bp__tablet--sm) {
    @include grid(3, 10px);
  }
}

.tri-buy__item {
  position: relative;
  @include clearfix;
}


.tri-buy__link {
  position: relative;
  display: block;
  background-color: #000;
  margin-bottom: 10px;

  // Double bars
  &:after,
  &:before {
    z-index: 1;
    @include psuedo;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0px;
    height: 2px;
    background-color: #fff;
    opacity: 0;
    transition: all .4s .05s $timing--simmer;
  }
  &:before {
    transform: translate(-50%, -3px);
  }
  &:after {
    transform: translate(-50%, 3px);
  }
  &:hover:before,
  &:hover:after {
    opacity: 1;
    width: 120px;
    transition-delay: 0;
  }
}

.tri-buy__img {
  opacity: 1;
  transition: all .2s $timing--butter;
  position: relative;

  .no-touchevents a:hover & {
    opacity: .7;
  }
}

.tri-buy__color {
  z-index: $z-index--tri-buy__color;
  position: absolute;
  top: 10px;
  right: 12px;
  opacity: 1;
  transition: opacity .2s $timing--butter;

  @include sans(14px, bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $blue-dk;

  // Display rules
  display: none;
  .touch & {
    display: block;
  }

  .no-touchevents a:hover & {
    opacity: 0;
  }
}

.tri-buy__h,
.tri-buy__deck {
  @include reset;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all .2s $timing--simmer;

  color: #fff;

  .no-touchevents a:hover & {
    opacity: 1;
  }
}

.tri-buy__h {
  @include serif(60px);
  transform: translate(-50%, -220%);

  .no-touchevents a:hover & {
    transform: translate(-50%, -130%);
  }
}

.tri-buy__deck {
  @include sans(24px, medium);
  transform: translate(-50%, 150%);

  .no-touchevents a:hover & {
    transform: translate(-50%, 60%);
  }
}

