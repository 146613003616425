// =============================================

// Troubleshooting

// =============================================

.troubleshooting-page {
	.ftr {
		display: none;
	}
}

// --------------------------------------------
// Layout
// --------------------------------------------


.ts__content {
	position: relative;
}

.ts__question-content {


	@include mq($bp__tablet--sm) {
		padding-top: 80px;
	}
}

.ts__marquee {
	max-width: 550px;

	&--has-instructions {
		margin-bottom: 48px;
	}
}

.ts__questions {
	padding: 0;
	margin: 0;
}

.ts__question-item {
	position: relative;
	@include clearfix;
	counter-increment: question-counter;
	position: relative;
	list-style: none;
	padding-left: 30px;
	margin-bottom: 80px;

	@include mq($bp__phablet) {
		padding-left: 60px;
	}

	@include mq($bp__tablet) {
		padding-left: 100px;
		margin-bottom: 120px;
	}

	&:before {
		content: counter(question-counter);
		@include absolute(5px x x -10px);
		background-color: $navy;
		color: #FFF;
		min-width: 29px;
		@include padding(0 10px 0 9px);

		text-align: center;
		@include sans(15px, "bold");
		line-height: 29px;
		box-sizing: border-box;


		@include mq($bp__phablet) {
			font-size: 18px;
			left: 0;
			@include padding(5px 15px);
		}

		@include mq($bp__tablet) {
			font-size: 21px;
			top: 7px;
			@include padding(10px 20px);
		}
	}
}

.ts__instructions {
	clear: both;
	position: relative;
}



// --------------------------------------------
// Text
// --------------------------------------------

.ts__text-item {
	max-width: 550px;
	position: relative;
	z-index: 10;
	@include margin(0 0 30px);

	@include mq($bp__tablet--sm) {
		margin-bottom: 60px;
	}

	&--right {
		float: right;
	}

	> .txt:last-child {
		margin-bottom: 0;
	}
}

.ts__h {
	margin-top: 50px;
	text-transform: capitalize;
}

.ts__h3 {
	@include margin(0 x x 0);
	&:before {
		display: none;
	}
}

.page__h--ts {
	margin-left: 0;
}

.ts__instructions-list {
	@include clearfix;
	padding: 0;
}

.ts__instructions-list-item {
	counter-increment: step-counter;
	position: relative;
	list-style: none;
	margin-bottom: 80px;
	&:last-child {
		margin-bottom: 0;
	}

	.txt--sm {
		@include mq($bp__tablet, $bp__desktop) {
			font-size: 16px;
		}
	}

	&:before {
		content: counter(step-counter, upper-alpha);
		@include absolute(0 x x -40px);
		border: 3px solid $navy;
		color: $navy;
		background-color: #FFF;
		@include padding(0 7px);

		@include sans(14px, "bold");
		line-height: 24px;
		text-align: center;

		@include mq($bp__phablet) {
			left: -50px;
			@include padding(1px 10px);
			line-height: 26px;
		}

		@include mq($bp__tablet--sm) {
			font-size: 16px;
			line-height: 30px;
			left: -60px;
			@include padding(1px 12px);
		}
		@include mq($bp__tablet) {
			left: -90px;
		}
	}
}

.ts__grid-half {
	@include grid(1);

	@include mq($bp__tablet) {
		@include grid(60% 40%, 50px);
	}
}

.ts__shape-bg--triangle {
	position: relative;

	&:before {
		content: "";
		background-image: svg-url("troubleshooting--shape-triangle.svg");
  	background-size: contain;
  	background-repeat: no-repeat;
		max-width: 330px;
		width: 70%;
		height: 100%;
		@include absolute(0 0 x x);
		display: block;
		z-index: 1;

		@include mq($bp__tablet--sm) {
			right: -30px;
		}
	}
}

.ts__shape-bg--circle {
	position: relative;

	&:before {
		content: "";
		background-image: svg-url("troubleshooting--shape-circle.svg");
  	background-size: contain;
  	background-repeat: no-repeat;
		max-width: 330px;
		background-color: none;
		width: 300px;
		height: 300px;
		@include absolute(-30px x x -50%);
		display: block;
		z-index: 1;

		@include mq($bp__tablet--sm) {
			left: -180px;
			top: -40px;
		}
	}
}


// --------------------------------------------
// Section Specific Styles
// --------------------------------------------

.ts__img--pins {
	margin-bottom: 20px;
}

.ts__img--remove-screws {
	max-width: 80%;
}

.ts__img--lift-up {
	margin-bottom: 30px;
}

.ts__img--spring-loaded {
	margin-top: -15px;
	@include mq($bp__tablet--sm) {
		margin-top: -20px;
	}
}


// --------------------------------------------
// Nav
// --------------------------------------------

.ts-nav__list-grid {
	@include grid(1);
	@include mq($bp__tablet) {
		@include grid(2, 40px);
	}
}

.ts-nav__list {
	margin: 0;
	padding-left: 20px;
	transition: all 0.2s $timing--butter;

	&--is-hidden {
		opacity: 0;
		transform: translateX(60px);
	}
}

.ts-nav__list-item {
	@include sans(18px, "med");
	margin-bottom: 6px;
	color: $red;

	@include mq($bp__tablet--sm) {
		font-size: 21px;
	}

	@include mq($bp__desktop) {
		font-size: 24px
	}
}

.ts-nav__link {
	text-decoration: none;
	transition: color 0.2s $timing--butter;
	color: $navy;

	&:hover {
		color: $red;
	}
}
