// =============================================

// Landing Page

// =============================================

// --------------------------------------------
// Hero
// --------------------------------------------

.l-landing__section {
	padding: 80px 20px 100px;
	
	@include mq($bp__tablet) {
		@include padding(x 30px x);
	}

	@include mq($bp__desktop--xl) {
		@include padding(x 0 x);
	}
}

.l-landing__section--gray-bg {
	background-color: #ebebef
}

.l-landing__section--tan-bg {
	background-color: $beige;
}

.hero--landing {
	width: 100%;
	background-size: cover;
	background-position: center center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.hero__img--landing {
  width: 100%;
}

.hero__h--landing {
	font-size: 48px;
	text-align: left;

	@include mq($bp__tablet) {
		font-size: 64px;
	}

	@include mq($bp__desktop) {
		font-size: 72px;
	}

	@include mq($bp__desktop--xl) {
		font-size: 96px
	}
}

.hero__deck--landing {
	margin: 0;
	text-align: left;

	@include mq($bp__desktop) {
		font-size: 30px;
	}

	@include mq($bp__desktop--xl) {
		font-size: 34px;
	}
}

.landing__section--sales {
	position: relative;
	padding-bottom: 0;
}

.hero__landing--basket,
.landing__section--details {
  max-width: $site-max-width;
  margin-left: $site-margin;
  margin-right: $site-margin;
  padding: 100px 0;
  @include mq($bp__narrow--md) {
  	padding: 120px 0;
    margin-left: 60px;
    margin-right: 60px;
  }
  @include mq($bp__narrow--lg) {
  	padding: 150px 0;
    margin-left: 120px;
    margin-right: 120px;
  }
  @include mq(1440px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.hero__landing-h-group {
	width: 100%;

	@include mq($bp__tablet) {
		max-width: 60%;
	}

	@include mq($bp__tablet) {
		max-width: 60%;
		padding-left: 30px;
	}

	@include mq($bp__desktop--xl) {
		padding-left: 0;
	}
}

.landing__video-btn {
	position: relative;
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
	opacity: 1;
	float: none;
	margin-top: 36px;
	
	@include mq($bp__tablet) {
		margin-top: 48px;
	}

	@include mq($bp__desktop) {
		margin-top: 64px;
	}
}

// --------------------------------------------
// Type
// --------------------------------------------

.landing__h2 {
	font-size: 50px;
	text-align: center;
	max-width: 700px;
	margin: 0 auto 20px;

	@include mq($bp__tablet) {
		font-size: 68px;
	}

	@include mq($bp__desktop) {
		font-size: 72px;
	}

	@include mq($bp__desktop--xl) {
		font-size: 92px;
	}
}

.landing__text {
	@include sans(21px, 'regular');
	color: $blue-dark;

	@include mq($bp__tablet) {
		font-size: 24px;
	}
}

.landing__txtgroup {
	margin-bottom: 40px;

	@include mq($bp__tablet) {
		margin-bottom: 80px;
	}

	@include mq($bp__desktop) {
		margin-bottom: 100px;
	}
}

.landing__h2--feature,
.landing__feature-text {
	margin-bottom: 30px;

	@include mq($bp__tablet) {
		max-width: 700px;
		margin-bottom: 40px;
	}

	@include mq($bp__desktop) {
		margin-bottom: 60px;
	}
}

.landing__txt {
	margin-top: 20px;

	@include mq($bp__tablet) {
		margin-top: 80px;
	}

	@include mq($bp__desktop) {
		margin-top: 100px;
	}
}

// --------------------------------------------
// Feature
// --------------------------------------------

.landing__feature-img {
	width: 100%;
	max-width: 200px;
	margin-right: 30px;

	@include mq($bp__tablet) {
		margin-bottom: 30px;
		padding: 0;
		max-width: 100%;
		margin-right: 0;
		width: 100%;
	}

	@include mq($bp__desktop) {
		padding: 0 10px 0;
	}
	
	@include mq($bp__desktop--xl) {
		padding: 0 30px 0;
	}

	&--border {
		border-radius: 100%;
	}
}

.landing__feature {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.landing__feature-item {
	position: relative;
	width: 100%;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: baseline;
	
	@include mq($bp__tablet) {
		width: 33.3%;
		padding: 0 30px 0;
		margin-bottom: 0;
		flex-wrap: wrap;
	}
}

.landing__feature-h-group {
	width: 100%;
			display: flex;
		justify-content: center;
		flex-direction: column;

	@include mq($bp__tablet) {

	}
}

.landing__feature-h,
.landing__feature-txt {
	@include serif(24px);
	margin: 0;

	@include mq($bp__tablet) {
		font-size: 30px;
	}

	@include mq($bp__desktop) {
		font-size: 36px;
	}
}

.landing__feature-h {
	color: $red;
}

.landing__feature-txt {
	color: $blue-dark;
}

.diamond--landing {
	top: -96px;
	left: 80px;
	right: auto;
}

.diamond__h {
  position: relative;
  margin: 30px 0 39px 0;
	
  @include serif(50px);

  &:before,
  &:after  {
    @include psuedo(n 0 n 0);
    transition: all .3s $timing--butter;
    border-bottom: 3px solid $red;
  }
  &:before {
    bottom: -17px;
  }
  &:after {
    bottom: -25px;
  }
}

// --------------------------------------------
// Compare Chart
// --------------------------------------------

.compare {
	position: relative;
	margin-bottom: 150px;

	@include mq($bp__tablet) {
		margin-top: 80px;
	}

	@include mq($bp__desktop) {
		margin-top: 100px;
	}
}

.compare__table {
	background-color: $beige;
	padding: 60px 0;
	
	@include mq($bp__tablet) {
		padding: 80px 0;
	}

	@include mq($bp__desktop) {
		padding: 160px 0;
	}
}

.compare__table-head {

	@include mq($bp__tablet) {
		margin-bottom: -100px;
	}

	@include mq($bp__desktop) {
		margin-bottom: -160px;
	}
}

.l-landing__section--compare {
	padding: 0;
}

.compare__heading {
	text-align: center;
	position: relative;
	width: 100%;
	@include sans(16px, "bold");
	letter-spacing: 1px;
	color: $blue-dark;
	text-transform: uppercase;

	&:before {
		@include pseudo;
		@include absolute(50% x x x);
		display: block;
		width: 100%;
		border-top: 1px solid $gray;
		z-index: 1;
	}
}

.compare__stat {
	@include sans(24px, "regular");
}

.compare__brand {
	@include sans(18px, "bold");
	letter-spacing: 1px;
	color: $blue-dark;
	text-transform: uppercase;
	text-align: center;
	margin-top: 0;

	.nav__logo-icon {
		width: 120px;

		@include mq($bp__tablet) {
			width: 160px;
		}

		@include mq($bp__desktop) {
			width: 180px;
		}

		.logo__letter {
			visibility: visible;
			transform: none;
		}
	}
} 

.compare__item {
	max-width: 1260px;
	margin: 0 auto;
	padding: 0 40px;

	&:first-child {
		margin-bottom: 40px;
	}

	@include mq($bp__desktop) {
		padding: 0 30px;
		max-width: 1260px;
	}
}

.compare__title {
	display: inline-block;
	background-color: #FFFFFF;
	padding: 0 30px;
	z-index: 3;
	position: relative;
}

.compare__row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@include mq($bp__tablet--sm) {
		flex-wrap: nowrap;
	}

	&--products {
		align-items: center;
	}
}

.compare__col {
	width: 100%;
	text-align: right;
	padding: 20px 0;
	position: relative;
	border-bottom: 1px solid lighten($gray, 20%);

	&:last-child {
		border: 0;
	}

	&:before {
		content: attr(data-brand);
		display: block;
		@include absolute(x x x 0);
		@include sans(14px, "bold");
		letter-spacing: 1px;
		color: $blue-dark;
		line-height: 50px;
		text-transform: uppercase;

		@include mq($bp__tablet--sm) {
			display: none;
		}
	}

	@include mq($bp__tablet--sm) {
		flex: 1 1 auto;
		text-align: center;
		border: 0;
	}
}

.compare__col--img {
	width: 100%;
	padding: 0 20px;
	position: relative;

	img {
		width: 100%;
	}

	@include mq($bp__tablet) {
		width: 33.3%;
	}

	&:nth-child(2) {
		&:before {
			content: "";
			display: block;
			@include absolute(-20px 0 0 0);
			@include svg(landing__burst, 100%);
			@include size(100%);
			background-size: cover;
			z-index: -1;
			animation: rotate 50s linear infinite;
		}
	}
}

@keyframes rotate { 
	to {
		transform: rotate(360deg); 
	} 
}

.compare__x,
.compare__check {
	@include size(50px);
	border-radius: 100%;
	display: inline-block;
	position: relative;

	&:before {
		@include pseudo;
		@include absolute(50% x x 50%);
		display: block;
	}
}

.compare__x {
	background-color: $red;

	&:before {
		@include svg(landing__x, 100%);
		@include size(18px 20px);
		@include margin(-10px x x -9px);
	}
}

.compare__check {
	background-color: $blue;

	&:before {
		@include svg(landing__check, 100%);
		@include size(22px);
		@include margin(-11px x x -11px);
	}
}