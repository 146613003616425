// =============================================

// Press Page

// =============================================


// --------------------------------------------
// Variables
// --------------------------------------------

$press__max-width: 550px;
$press__text-indent--sm: 15px;
$press__text-indent--md: 18px;
$press__text-indent--lg: 26px;


// --------------------------------------------
// Page Styles
// --------------------------------------------

.page-id-10 {
  .mc-h,
  .mc-submit {
    color: $red;
  }
  .mc-input {
    border-color: $red;
  }

}


// --------------------------------------------
// Verbatim
// --------------------------------------------

.verbatim__list {
  @include reset(ul);
  @include clearfix;
}

.verbatim__item {
  position: relative;
  max-width: $press__max-width;
  margin: 0 0 90px $press__text-indent--sm;

  @include mq($bp__phone) {
    margin-bottom: 125px;
  }

  @include mq($bp__tablet) {
    width: 75%;
    clear: both;
    &:nth-child(even) {
      float: right;
    }
  }
}

.verbatim__link {
  text-decoration: none;
  @include underline-hover(-2px);

  .no-touchevents &:hover:after {
    width: 100%;
    left: 0;
  }
}

.verbatim__h {
  position: relative;
  z-index: $z-index--shape__h;

  @include serif(41px, .9);
  color: $red;
  text-indent: $press__text-indent--sm * -1;
  margin-bottom: 10px;
  @include mq($bp__phablet) {
    font-size: 62px;
    text-indent: $press__text-indent--md * -1;
  }
  @include mq($bp__tablet) {
    font-size: 70px;
    text-indent: $press__text-indent--lg * -1;
  }
}

.verbatim__src {
  @include sans(21px, medium);
  margin: 0;
  color: $blue-dk;

  @include mq($bp__phablet) {
    font-size: 24px;
  }
}

// --------------------------------------------
// Backgrounds
// --------------------------------------------

.verbatim__shape-bg {
  width: 70%;
}

.verbatim__shape-bg--triangle {
  max-width: 330px;
  top: -60px;
  right: -10px;
  @include mq($bp__narrow--md) {
    right: -40px;
  }
  @include mq($bp__narrow--lg) {
    right: -80px;
  }
}

.verbatim__shape-bg--circle {
  max-width: 345px;
  top: -55px;
  left: -100px;
  @include mq($bp__narrow--md) {
    top: -70px;
    left: -160px;
  }
  @include mq($bp__narrow--lg) {
    top: -120px;
  }
}

.verbatim__shape-bg--square {
  max-width: 370px;
  top: 0px;
  right: -40px;
  @include mq($bp__narrow--md) {
    top: -30px;
    right: 0px;
  }
  @include mq($bp__narrow--lg) {
    top: -10px;
    right: 110px;
  }
}



// --------------------------------------------
// All Press
// --------------------------------------------

.press__all {
  position: relative; // necessary because of the background shape
}

.press__all-h {
  float: right;
  margin-bottom: 60px;
  @include mq($bp__phablet) {
    margin-right: 30px;
    margin-bottom: 100px;
  }
  @include mq($bp__tablet--sm) {
    margin-right: 60px;
  }
}

.press__list {
  @include reset(ul);
  clear: both;
}

.press__item {
  max-width: $press__max-width;
  margin-bottom: 60px;
  margin-left: $press__text-indent--sm;

  @include mq($bp__phablet) {
    margin-left: $press__text-indent--md;
  }

  @include mq($bp__tablet) {
    margin-left: $press__text-indent--lg;
    margin-bottom: 80px;
  }

}

.press__link {
  text-decoration: none;
}

.press__src {
  @include serif(41px);
  color: $blue;
  margin-bottom: 10px;

  @include mq($bp__tablet) {
    font-size: 55px;
  }
}

.press__txt {
  @include sans(21px, medium);
  color: $blue-dk;
  margin: 0;
  &:hover {
    text-decoration: underline;
  }

  @include mq($bp__phablet) {
    font-size: 24px;
  }

  @include mq($bp__tablet) {
    font-size: 28px;
  }
}
