// =============================================

// Buy Modal

// =============================================

.buy-modal__popup {
  position: relative;
  background: #FFF;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

.buy-modal__img-container {
	position: relative;
	padding-top: 50%;
}

.buy-modal__img {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

.buy-modal__img--current {
	z-index: 10;
}

.buy-modal__content {
	@include padding(30px 20px);
	text-align: center;

	@include mq($bp__tablet--sm) {
		@include padding(x 35px);
	}
}

.buy-modal__h {
	@include serif(23px);
  color: $red;
  margin: 0 0 8px;

  @include mq($bp__phablet) {
    font-size: 27px;
    display: block;
  }

  @include mq($bp__tablet--sm) {
    font-size: 36px;
  }
}

.buy-modal__txt {
	font-size: 16px;
	margin: 12px 0 14px 0;
  @include mq($bp__tablet--sm) {
    font-size: 20px;
    margin: 18px 0 20px 0;
  }
}

.buy-modal__link {
	display: inline-block;
	margin-top: 25px;
  letter-spacing: -0.025;
  text-decoration: none;
  color: $gray;
  @include sans(16px, bold);
  @include link-hoverline;
}

.buy-modal__checkout,
.buy-modal__reset {
	position: relative;
  display: block;
  @include padding(20px 10px 18px);
  color: #FFF;
  @include sans(16px, bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  transition: background 0.3s $timing--butter;

  .no-touchevents &:hover {
		background: $blue-hover;
  }

  &--is-disabled {
  	opacity: 0.5;
  	pointer-events: none;
  }

  @include mq($bp__tablet--sm){
		font-size: 18px;
	}
}

.buy-modal__checkout {
	background: $blue;
}

.buy-modal__reset {
	border: 3px solid $gray;
	color: $gray;
	margin-top: 15px;
	display: none;

	.buy-modal--multiple-in-view & {
		display: block;
	}

	.no-touchevents &:hover {
		border: 3px solid $red;
    background-color: $red;
		color: #FFF;
	}
}

// --------------------------------------------
// Multiple
// --------------------------------------------

$multiple-height--sm: 120px;
$multiple-height: 150px;

%buy-text {
	color: $navy;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.buy-multiple {
	position: relative;
	display: none;
	opacity: 0;
}

.buy-multiple__list {
	padding: 0;
	border: 0;
	outline: 0;
}

.buy-multiple__img {
	position: relative;
	width: 100%;
	height: $multiple-height--sm;
	background-size: cover;
	background-repeat: no-repeat;

	@include mq($bp__phablet) {
		height: $multiple-height;
	}
}

.buy-multiple__items {
	min-height: $multiple-height--sm;
	display: flex;
	align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  @include mq($bp__tablet--sm) {
  	padding: 0 20px;
  	height: $multiple-height;
  }
}

.buy-multiple__grid {
	@include grid(40% 60%);

	@include mq($bp__phablet) {
		@include grid(34% 66%);
	}
}

.buy-multiple__center {
	position: relative;
}

.buy-multiple__list-item {
	position: relative;
	list-style: none;
	padding: 0;
	@include clearfix;
	border-top: 2px solid lighten($gray, 20%);

	&:first-child {
		border-top: 0;
	}
}

.buy-multiple__product {
	display: block;
	text-align: left;
	@include sans(24px, med);

	.no-flexbox & {
		float: left;
		padding-top: 50px;
	}
}

.buy-multiple__number {
	display: inline-block;
	outline: 0;
	max-width: 60px;
	@include sans(24px, bold);
	color: $navy;
	border: 0;
	@include padding(5px x 5px 10px);

	@include mq($bp__tablet--sm) {
		font-size: 30px;
		max-width: 80px;
	}

	.no-flexbox & {
		float: right;
		padding-top: 50px;
	}
}

.buy-multiple__total,
.buy-multiple__qty {
	@extend %buy-text;
	position: relative;
	@include sans(24px, bold);

	@include mq($bp__tablet--sm) {
		font-size: 30px;
	}
}

.total__text,
.qty__text {
  display: block;
	font-size: 14px;
}

.buy-multiple__total {
	float: left;
	text-align: left;
	@include padding(25px x x 25px);

	@include mq($bp__tablet--sm) {
		@include padding(35px x x 35px);
	}
}

.buy-multiple__qty {
	float: right;
	text-align: right;

	@include padding(25px 25px x x);

	@include mq($bp__tablet--sm) {
		@include padding(35px 35px x x);
	}
}

.buy-modal__link--wholesale {
	display: none;

	.buy-modal--multiple-in-view & {
		display: block;
	}
}

// --------------------------------------------
// Select
// --------------------------------------------

.buy-select  {
	position: relative;
	margin-bottom: 25px;

	select {
    @include reset(input);
		display: block;
		padding: 10px 12px 10px 12px;
		margin: 0;
		transition: border-color 0.2s;
		border: 2px solid $gray-dark;
		width: 100%;
		background: #fff;
		@include sans(16px, bold);
		color: darken($gray-dark,12%);
    text-align: center;
    text-shadow: none;

		@include mq($bp__tablet--sm){
			font-size: 18px;
		}
	}
}

.buy-select__arrow {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  pointer-events: none;

	&:before {
		content: "▾";
		position: absolute;
		top: 50%;
		right: 15px;
		margin-top: -9px;
		pointer-events: none;
		color: $gray-dark;
	}
}


.mfp__buy-modal.mfp-bg {
  opacity: 0;
  background-color: $blue-dark;
  transition: all 0.15s ease-out;
}

.mfp__buy-modal.mfp-bg.mfp-ready {
  opacity: 0.95;
}

.mfp__buy-modal.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp__buy-modal.mfp-wrap .mfp-content {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.15s ease-out;
}

.mfp__buy-modal.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  transform: translateY(0);
}

.mfp__buy-modal.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
