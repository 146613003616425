// =============================================

// Typography

// =============================================

@mixin serif(
  $size: 16px,
  $line-height: 1
  ){

  // Give us raw units
  $raw-size: strip-units($size);
  // Convert to px
  $size: $raw-size + 'px';

  font-size: #{$size};
  font-weight: normal;
  line-height: $line-height;
  text-rendering: optimizeLegibility;

  @if $raw-size > 40 {
    font-family: $eksell-lg;
  }
  @elseif $raw-size > 26
      and $raw-size < 40 {
    font-family: $eksell-md;
  }
  @else {
    font-family: $eksell-sm;
  }

} // serif


@mixin sans(
  $size: 16px,
  $weight: $font-weight--reg
  ){

  // Give us raw units
  $raw-size: strip-units($size);
  // Convert to px
  $size: $raw-size + 'px';

  // Set font-weight variable
  @if $weight == "reg"
      or $weight == "regular"
      or $weight == "300" {
    $weight: $font-weight--reg;
  }
  @else if $weight == "med"
        or $weight == "medium"
        or $weight == "500" {
    $weight: $font-weight--med;
  }
  @else if $weight == "b"
        or $weight == "bold"
        or $weight == "700" {
    $weight: $font-weight--bold;
  }

  font-family: $edmond;
  font-size: #{$size};
  font-weight: $weight;

  @if $raw-size > 30 {
    text-rendering: optimizeLegibility;
  }

} // sans



// --------------------------------------------
// Text
// --------------------------------------------

.txt {
  @include sans(18px);
  line-height: 1.5;
  color: $blue-dk;
  @include mq($bp__phablet) {
    font-size: 22px;
  }
}

.txt--sm {
  @include sans(18px);
  line-height: 1.5;
  color: $blue-dk;
}

.txt--lg {
  @include sans(18px);
  line-height: 1.5;
  color: $blue-dk;
  margin: 0 0 10px;
  @include mq($bp__phablet) {
    font-size: 22px;
  }
  @include mq($bp__tablet) {
    font-size: 24px;
  }
  @include mq($bp__desktop) {
    font-size: 27px;
  }
}

// --------------------------------------------
// Links
// --------------------------------------------

@mixin link-hoverline {
  // Link is NOT underlined
  // Hovering adds the underline
  &:hover {
    text-decoration: underline;
  }
}

.txt__link {
  @include sans(16px, bold);
  color: $link-gray;
  line-height: 1;
  margin-top: 15px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s $timing--butter;

  &:hover {
    color: $navy;
  }

  @include mq($bp__tablet) {
    font-size: 18px;
  }
}

.content__link {
  text-decoration: none;
}

// --------------------------------------------
// Headlines
// --------------------------------------------

.h1,.h2,.h3,.h4 {
  margin-top: 0;
  margin-bottom: 12px;
}

.h2 {
  @include serif(38px);
  color: $red;
  @include mq($bp__phablet) {
    font-size: 54px;
  }
  @include mq($bp__tablet) {
    font-size: 68px;
  }
}

.h3 {
  @include serif(36px);
  color: $blue;
  margin: 0 0 10px;
  @include mq($bp__phablet) {
    font-size: 44px;
  }
  @include mq($bp__tablet) {
    font-size: 48px;
  }
  @include mq($bp__desktop) {
    font-size: 56px;
  }
}



// --------------------------------------------
// Section Headers
// --------------------------------------------

.page__h {
  display: inline-block;
  position: relative;
  margin-left: -10px;

  @include sans(24px, bold);
  color: $blue-dk;
  text-rendering: optimizeLegibility;

  @include mq($bp__phablet) {
    font-size: 32px;
    margin-left: 0;
  }

  @include mq($bp__tablet) {
    font-size: 36px;
  }

  // Underline effect
  &:before {
    @include psuedo(n -2px -1px -2px);
    border-bottom: 4px solid $blue-dk;
  }
}

.page__h-alt {
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 19px;
  margin-bottom: 40px;

  @include mq($bp__tablet--sm) {
    font-size: 24px;
  }

  &:before {
    border-width: 3px;
    bottom: -5px;
    @include mq($bp__tablet--sm) {
      bottom: -6px;
      border-width: 4px;
    }
  }
}

.section__h2 {
  @include serif(50px);
  color: $blue;
  margin: 0 0 25px;
  @include mq($bp__phablet) {
    font-size: 68px;
  }
  @include mq($bp__tablet) {
    font-size: 100px;
  }
}

.section__h4 {
  position: relative;

  @include sans(18px, bold);
  color: $blue-dk;

  &:after {
    @include psuedo(n -2px -2px -2px);
    border-bottom: 2px solid $blue-dk;
  }

}


// --------------------------------------------
// Shape Typography
// --------------------------------------------

.shape-h {
  @include serif(32px);
  margin: 0 0 10px 0;
  color: $blue-dk;
}

.shape-txt,
.shape-txt--upper {
  @include sans(16px, bold);
  margin: 0;
}

.shape-txt--upper {
  text-transform: uppercase;
  letter-spacing: 1px;
}
