// =============================================

// Objects

// =============================================


@mixin diamond($size, $target: '*') {
  @include size($size);

  position: relative;
  display: block;
  transform: rotate(45deg);
  transform-origin: center center;

  > #{$target} {
    transform: rotate(-45deg);
    @include absolute(0 0 0 0);
  }
}


// --------------------------------------------
// Cicle Image
// --------------------------------------------

.img--circle {
  display: block;
  border-radius: 100%;
  border: 6px solid transparent;

  @include mq($bp__desktop) {
    border-width: 10px;
  }
}

// --------------------------------------------
// Shape Backgrounds
// --------------------------------------------

.shape-bg {
  opacity: .25;
  display: block;
  position: absolute;
  .backgroundblendmode &:after {
    mix-blend-mode: multiply;
  }
}


// --------------------------------------------
// Diamond
// --------------------------------------------

@mixin shape-defaults {
  position: relative;
  display: block;
  border: $shape-border;
  text-align: center;
}

.circle__inner,
.diamond__inner,
.square__inner {
  padding: 17px;

    &:after,
    &:before {
      @include psuedo;
      @include absolute-center(horizontal);
      width: 20px;
      height: 3px;
      background-color: $red;
      transition: all .2s $timing--butter;
    }
    &:before {
      top: 18px;
    }
    &:after {
      bottom: 18px;
    }
    a:hover &:before {
      transform: translateX(-50%)
                 translateY(5px);
    }

    a:hover &:after {
      transform: translateX(-50%)
                 translateY(-5px);
    }
} // shape-defaults

.diamond {
  @include diamond(150px);
  @include shape-defaults;

  @include mq($bp__tablet--sm) {
    @include size(170px);
  }

  &__inner {
    &:before {
      top: 8px;
    }
    &:after {
      bottom: 8px;
    }

    a:hover &:before {
      transform: translateX(-50%)
                 translateY(-5px)
                 rotate(-90deg);
    }

    a:hover &:after {
      transform: translateX(-50%)
                 translateY(5px)
                 rotate(90deg);
    }
  }
}

.square {
  position: relative;
  width: 155px;
  height: 170px;
  @include shape-defaults;

  @include mq($bp__tablet--sm) {
    width: 175px;
    height: 190px;
  }

}

.circle {
  @include circle(170px);
  @include shape-defaults;
  @include mq($bp__tablet--sm) {
    @include size(190px);
  }
}

// --------------------------------------------
// Pentagon
// --------------------------------------------

// My cousin's pentagon mixin

@mixin pentagon(
  $box-width,
  $position,
  $base-border
) {

  $scale-factor: tan(30 * $PI/180);

  $rhom-border: $base-border * sqrt(2);
  $rhom-width: ($box-width - ($rhom-border * 2/sqrt(2))) / sqrt(2);
  $rhom-height: $rhom-width;

  $x-pos: ($rhom-width * sqrt(2) / 2) - ($base-border + $rhom-width / 2) + ($scale-factor * 3);
  $y-pos: ($base-border / sqrt(2) + $rhom-width / 2) * -1;

  position: relative;
  width: $box-width;
  @include border(0 $base-border 0 $base-border, $red);

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: $rhom-width;
    height: $rhom-height;
    transform: scaleY($scale-factor) rotate(-45deg);
    background-color: inherit;
  }

  @if $position == up {
    border-bottom: $base-border solid $red;

    &:before{
      left: $x-pos;
      top: $y-pos;
      @include border($rhom-border $rhom-border 0 0, $red);
    }
  } @elseif $position == down {
    border-top: $base-border solid $red;

    &:before{
      left: $x-pos;
      bottom: $y-pos;
      @include border(0 0 $rhom-border $rhom-border, $red);
    }
  }
}

.pentagon--up {
  @include margin(0 auto x auto);
  @include mq($bp__desktop) {
    @include pentagon(250px, up, 4px);
    margin-top: 100px;
    @include padding(25px 45px 45px 45px);
  }
  @include mq($bp__desktop--xl) {
    @include pentagon(300px, up, 4px);
    margin-top: 120px;
  }
}

.pentagon--down {
  @include margin(x auto 60px auto);
  @include mq($bp__desktop) {
    @include pentagon(250px, down, 4px);
    margin-bottom: 100px;
    @include padding(25px 25px 25px 25px);
  }
  @include mq($bp__desktop--xl) {
    padding-top: 40px;
    @include pentagon(300px, down, 4px);
    margin-bottom: 120px;
  }
}

// .pentagon {
//   position: relative;
//   width: 300px;
//   @include border(0 4px 0 4px, $red);

//   &:before,
//   &:after {
//     content: "";
//     position: absolute;
//     z-index: 1;
//     width: 207px;
//     height: 207px;
//     transform: scaleY(0.6) rotate(-45deg);
//     background-color: inherit;
//   }
// }

// .pentagon--up {
//   border-bottom: 4px solid $red;
//   @include margin(120px x x x);
//   @include padding(25px 45px 45px 45px);

//   &:before{
//     left: 40px;
//     top: -106px;
//     @include border(5px 5px 0 0, $red);
//   }
// }

// .pentagon--down {
//   border-top: 4px solid $red;
//   @include margin(x x 120px x);
//   @include padding(45px 45px 25px 45px);

//   &:after{
//     left: 40px;
//     bottom: -106px;
//     @include border(0 0 5px 5px, $red);
//   }
// }

// --------------------------------------------
// Buy Button
// --------------------------------------------

// It's diamond-shaped!

$buy-btn-size--sm: 120px;
$buy-btn-size--md: 170px;
$buy-btn-size--lg: 200px;

.buy-btn {
  display: block;
  position: relative;
  @include size($buy-btn-size--sm);
  border: 3px solid $red;

  transform: rotate(45deg);
  transform-origin: center center;

  color: $blue-dk;
  text-align: center;
  text-decoration: none;
  outline: 0;

  @include mq($bp__phablet) {
    border-width: 4px;
    @include size($buy-btn-size--md);
  }

  @include mq($bp__tablet) {
    @include size($buy-btn-size--lg);
  }
}

.buy-btn__inner {
  display: table;
  position: absolute;
  top: 12%;
  left: 77%;
  transform: rotate(-45deg)
             translateX(-50%)
             translateY(-50%);
  transform-origin: center center;
  transition: all .2s $timing--butter;
}

.buy-btn__h {
  position: relative;
  display: table-cell;
  vertical-align: middle;

  margin: 0;
  height: $buy-btn-size--sm;

  @include serif(43px);

  @include mq($bp__phablet) {
    height: $buy-btn-size--md;
    font-size: 70px;
  }

  @include mq($bp__desktop) {
    height: $buy-btn-size--lg;
    font-size: 80px;
  }
}

.buy-btn__txt {
  @include sans(18px, medium);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

.buy-btn__lines--top,
.buy-btn__lines--btm {
  &:before,
  &:after  {
    @include psuedo(n 0 n 0);
    transition: all .3s $timing--butter;
    border-bottom: $shape-border;
    width: 50%;
    margin: auto;
  }

  // Hover states
  a:hover &:after {
    left: -6px;
    right: 6px;
  }
  a:hover &:before {
    left: 6px;
    right: -6px;
  }
}

.buy-btn__lines--top {
  &:before {
    top: 11%;
  }
  &:after {
    top: 15%;
  }

  @include mq($bp__desktop) {
    &:before {
      top: 33px;
    }
    &:after {
      top: 26px;
    }
  } // mq
}

.buy-btn__lines--btm {
  &:before {
    bottom: 10%;
  }
  &:after {
    bottom: 14.25%;
  }

  @include mq($bp__desktop) {
    &:before {
      bottom: 23px;
    }
    &:after {
      bottom: 30px;
    }
  } // mq
}
