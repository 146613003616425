// --------------------------------------------
// Base Files
// --------------------------------------------

@import "project/variables";
@import "project/project-mixins";
@import "project/fonts";

// --------------------------------------------
// Libraries
// --------------------------------------------

// Swiper
@import "project/swiper";
// Magnific Popup
@import "magnific/main";


// --------------------------------------------
// Components
// --------------------------------------------

@import "../../bower_components/mathsass/dist/math";
@import "/wp-content/themes/bucaboot/bower_components/normalize.css/normalize.css";
@import "project/universal";
@import "project/themes";
@import "project/type";
@import "project/layout";


// --------------------------------------------
// Partials
// --------------------------------------------

@import "project/objects";
@import "project/nav";
@import "project/footer";
@import "project/nag";
@import "project/buy-modal";


// --------------------------------------------
// Pages
// --------------------------------------------

@import "pages/homepage";
@import "pages/register";
@import "pages/install";
@import "pages/press";
@import "pages/about";
@import "pages/details";
@import "pages/faqs";
@import "pages/troubleshooting";
@import "pages/blog";
@import "pages/404";
@import "pages/landing";
@import "pages/sales";