// =============================================

// Site Footer

// =============================================

iframe[name='google_conversion_frame'] {
  display: table;
  height: 0;
}

// --------------------------------------------
// Background Image
// --------------------------------------------

.ftr {
  padding-top: 160px;
  @include mq($bp__tablet--sm) {
    padding-top: 280px;
  }
}

// --------------------------------------------
// Mailchimp Signup
// --------------------------------------------

$mc-height: 54px;

.email-signup-form {
  position: relative;
}

.mc-hidden-field {
  display: none;
}

.mc-h {
  @include serif(36px);
  color: $red;
  margin: 0 0 8px;

  @include mq($bp__phablet) {
    font-size: 42px;
  }

  @include mq($bp__tablet--sm) {
    font-size: 48px;
  }
}

.mc-txt {
  display: block;
  margin-bottom: 24px;
}

.mc-mod {
  position: relative;
  text-align: center;
  max-width: 480px;
  margin: auto;
}

.mc-input,
.mc-submit {
  @include reset(input);
}

.mc-input {
  @include sans(16px, medium);
  color: $blue-dk;

  display: block;
  width: 100%;
  height: $mc-height;
  padding: 17px;
  border: 2px solid $red;
}

.mc-submit {
  display: block;
  color: $red;
  @include size($mc-height - 10);
  @include absolute(n 5px 5px n);
  background-color: rgba(255,255,255,.5);
  transition: background-color .2s;
  &:hover {
    background-color: rgba(255,255,255,.75);
  }
}

.mc-mod,
.social-list {
  margin-bottom: 40px;

  @include mq($bp__phablet) {
    margin-bottom: 60px;
  }
}


// --------------------------------------------
// Social Icons
// --------------------------------------------

.social-list {
  text-align: justify;
  font-size: 0.1px; // Hide whitespace between elements
  width: 160px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  padding: 0;
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

.social-list--nav {
  margin-bottom: 84px;
  
  .social-item {
    @include increment-delay($offset: .08s, $count: 4);
    opacity: 0;
    transform: translateY(-8px);
    transition: all 0.2s $timing--butter;
    
    .site-nav-in-view & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include mq($bp__tablet, "max") {
    margin-left: 0;
    margin-right: 0;
  }
}

.social-item {
  display: inline-block;
  width: 32px;
}

.social-link {
  top: 0;
  position: relative;
  display: block;
  @include size(32px);
  opacity: .85;
  transition: all .125s ease-in;
  &:hover {
    top: -2px;
    opacity: 1;
  }

  .twitter > & {
    @include svg('twitter-dark');
  }

  .facebook > & {
    @include svg('facebook-dark');
  }

  .google > & {
    @include svg('google-dark');
  }

  .instagram > & {
    @include svg('instagram-dark');
  }

} // social-link

.social-link--blue {
  .twitter > & {
    @include svg('twitter-blue');
  }

  .facebook > & {
    @include svg('facebook-blue');
  }

  .google > & {
    @include svg('google-blue');
  }

  .instagram > & {
    @include svg('instagram-blue');
  }
}

// --------------------------------------------
// Contact
// --------------------------------------------

.contact {
  margin: 0 auto;
  max-width: 650px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.contact__grid {
  @include mq($bp__tablet--sm) {
    @include grid(2, 40px);
  }
}

.contact__item {
  text-align: center;
}

.contact__link {
  @include sans(18px);
  display: inline-block;
  text-decoration: none;
  line-height: 1.5;
  color: $blue-dk;
  @include underline-hover(-4px);

  @include mq($bp__phablet) {
    font-size: 22px;
  }
}

.contact__h {
  @include serif(24px);
  color: $red;
  @include margin(30px x 10px);

  @include mq($bp__phablet) {
    font-size: 24px;
  }

  @include mq($bp__tablet--sm) {
    font-size: 30px;
    margin-top: 0;
  }
}

// --------------------------------------------
// Copyright
// --------------------------------------------

.ftr-bottom {
  width: 100%;
  text-align: center;
  padding: 20px 12px;
  margin: 0 auto;
  background-color: $blue-dk;
}

.copyright__txt {
  font-size: 14px;
  margin: 0;
  color: #fff;
}

.ftr-bottom__list {
  list-style: none;
  display: block;
  margin: 4px 0 0 0;
  padding: 0 0 10px;

  @include mq($bp__desktop--xl) {
    margin: 0 0 0 auto;
  }
}

.ftr-bottom__list-item {
  display: block;
  padding-bottom: 8px;
  padding-right: 20px;
  color: #FFFFFF;
  @include sans(13px, "bold");

  @include mq($bp__tablet--sm) {
    display: inline-block;
    padding-bottom: 0;
  }
}

.ftr-bottom__link {
  text-decoration: none;

  &:hover {
    color: $blue;
  }
}