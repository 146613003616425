// =============================================

// Theme

// =============================================


.bg-alt {
  background-color: $bg;
}


// --------------------------------------------
//
//  Theme - Alt
//
//  ---
//
//  Apply this theme to page body,
//  and the entire background will
//  change from white to site bg
//
// --------------------------------------------

.t-alt {
  .content {
    background-color: $bg;
  }
  .ftr {
    @include faux-background--pseudo(100px);

    @include mq($bp__tablet--sm) {
      @include faux-background--pseudo(160px);
    }
  }
}


// --------------------------------------------
//
//  Theme - Alt Full
//
//  ---
//
//  Apply this theme to page body,
//  and the entire background will
//  change from white to beige
//
// --------------------------------------------

.t-alt--full {
  .content,
  .ftr {
    background-color: $bg;
  }
}


// --------------------------------------------
//
//  Theme - Alt Top
//
//  ---
//
//  Apply this theme to page body,
//  and the header will change to beige
//  in anticipation of first component
//  also being assigned a beige background
//
// --------------------------------------------

.t-alt--top {
}


// --------------------------------------------
//
//  Theme - Alt Bottom
//
//  ---
//
//  Apply this theme to page body,
//  and the footer will change to beige
//
// --------------------------------------------

.t-alt--btm {
  .ftr {
    background-color: $bg;
  }
}

// --------------------------------------------
// 
// Nav Overlay Override
// 
// Override theme for when nav overlay is in view
// 
// --------------------------------------------

.t-alt,
.bg-alt,
.t-alt--full,
.t-alt--top,
.t-alt--btm {
  .hdr--overlay .hdr__wrapper {
    background: 0;
  }
}