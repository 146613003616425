// =============================================

// Layout

// =============================================


// --------------------------------------------
// Variables
// --------------------------------------------

$bp__narrow--md: $bp__tablet--sm;
$bp__narrow--lg: $bp__desktop;



// --------------------------------------------
// Universal Styles
// --------------------------------------------

.overlay-in-view {
  overflow: hidden;
}

.content {
  overflow: hidden;

  > div:first-child {
    padding-top: 140px;
  }
}

.resp-img {
  width: 100%;
}

// --------------------------------------------
// Individual Rules
// --------------------------------------------

.site-width {
  max-width: $site-max-width;
  margin-left: $site-margin;
  margin-right: $site-margin;
  @include mq(1240px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.site-width--flush {
  max-width: $site-max-width;
  @include mq($site-max-width) {
    margin-left: auto;
    margin-right: auto;
  }
}

.txt-width {
  max-width: $txt-max-width;
  margin-left: auto;
  margin-right: auto;
}

.txt-width--left {
  max-width: $txt-max-width;
}

.txt-width--lg {
  max-width: $txt-max-width--lg;
  margin-left: auto;
  margin-right: auto;
}

.l-narrow {
  max-width: $site-max-width;
  margin-left: $site-margin;
  margin-right: $site-margin;
  @include mq($bp__narrow--md) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @include mq($bp__narrow--lg) {
    margin-left: 120px;
    margin-right: 120px;
  }
  @include mq(1440px) {
    margin-left: auto;
    margin-right: auto;
  }
}
