// =============================================

// 404 Page

// =============================================
 
.content--404 {
	padding-top: 100px;
}

// --------------------------------------------
// Not Found Section
// --------------------------------------------

.not-found .ftr:before {
	background-color: #FFF;
}

.not-found__section {
	position: relative;
	@include clearfix;
}

.not-found__section--bike {
	padding-top: 55%;
}

.not-found__bike {
	@include absolute(0 -15px x x);
	width: 100%;
	transform: rotateZ(10deg);
}

// --------------------------------------------
// Not Found Type
// --------------------------------------------

.not-found__h {
	color: $red;
}

.not-found__message {
	position: relative;

	@include mq($bp__tablet) {
		position: absolute;
	}

	.not-found__section:nth-child(1) & {
		transform: rotateZ(8deg);
		width: 60%;
	}

	.not-found__section:nth-child(3) & {
		transform: rotateZ(-10deg);
	}

	.not-found__section:nth-child(4) & {
		transform: rotateZ(10deg);
		width: 60%;
	}
}

.not-found__message--right,
.not-found__message--left {
	@include padding(30px 10% x 10%);

	@include mq($bp__tablet) {
		top: 0;
		@include padding(60px 10% x 10%);
	}

	@include mq($bp__desktop) {
		@include padding(120px 10% x 10%);
	}
}

.not-found__message--right {
	@include mq($bp__tablet) {
		right: 0;
	}
}

.not-found__message--left {
	@include mq($bp__tablet) {
		left: 0;
		width: 55%;
	}
}

.big-number {
	@include serif();
	position: relative;
	color: $blue;
	font-size: 50vw;
	width: 50%;

	@include mq($bp__tablet) {
		width: 30%;
	}

	.not-found__section:nth-child(2) & {
		transform: rotateZ(8deg);
	}

	.not-found__section:nth-child(3) & {
		transform: rotateZ(14deg);
		z-index: 10;
	}

	.not-found__section:nth-child(4) & {
		transform: rotateZ(-6deg);
		z-index: 10;
		
		@include mq($bp__tablet) {
			margin-top: -150px;
		}
	}
}

.big-number--left {
	float: left;
}

.big-number--right {
	float: right;
}

// --------------------------------------------
// Flying Items
// --------------------------------------------

.flying-item {
	position: absolute;
}

.flying-item__img {
	width: 100%;
}

.flying-item--wine-bottle {
	width: 20%;
	bottom: -36%;
	left: 15%;
	transform: rotateZ(-14deg);
	z-index: 10;
	// animation-duration: 40s;
 //  animation-name: slowrotatebottle;
 //  animation-iteration-count: infinite;
}

.flying-item--album-cover {
	width: 30%;
	top: 0;
	right: 18%;
	transform: rotateZ(8deg);
}

.flying-item--shoe {
	width: 21%;
	top: 50%;
	right: 20%;
	transform: rotateZ(72deg);
	z-index: 10; 
}

.flying-item--bread {
	width: 45%;
	top: 50%;
	left: 6%;
	transform: rotateZ(61deg);
	z-index: 10;
}

.flying-item--ipad-case {
	width: 30%;
	top: 30%;
	right: 6%;
	transform: rotateZ(270deg);
	z-index: 10;
}

.flying-item--apple {
	width: 10%;
	top: 145%;
	left: 15%;
	transform: rotateZ(10deg);
	z-index: 10;
}

// @keyframes slowrotatebottle {
// 	0% {
// 		transform: rotateZ(-14deg);
// 	}
// 	50% {
// 		transform: rotateZ(0deg);
// 	}
// 	100% {
// 		transform: rotateZ(-14deg);
// 	}
// }