// =============================================

// Blog

// =============================================

//
// Blog Home Layout
//

.l-blog-home {}

.l-blog-archive {
	width: 100%;
	padding-top: 64px;
	@include clearfix;
}

.blog-archive__item {
	position: relative;
	margin-bottom: 48px;
	clear: both;

	@include mq($bp__tablet) {
		margin-bottom: 84px;
	}
	
	&:nth-child(odd) {
		float: left;
	}

	&:nth-child(even) {
		float: left;
		@include mq($bp__tablet) {
			float: right;
		}
	}
}

.blog-archive__item:nth-child(odd) {
	.no-touchevents &:hover .blog-archive__shape:before {
		transform: translate3d(-10px, -10px, -10px);
	}
}

.blog-archive__item:nth-child(even) {
	.no-touchevents &:hover .blog-archive__shape:before {
		transform: translate3d(10px, -10px, 10px);
	}
}

.blog-archive__shape {
	&:before {
		z-index: $z-index--1;
		transition: transform 2s $timing--easeOutQuint;
	}
}

.blog-archive__shape--square {
	
	&:before {
		content: "";
		@include svg(blog__shape--square, 100%);
		@include size(125px);
		display: block;

		@include mq($bp__phablet) {
			@include size(188px);
		}

		@include mq($bp__tablet) {
			@include size(250px);
		}
	}

	.blog-archive__item:nth-child(odd)  & {
		@include padding(32px x x 32px);

		@include mq($bp__phablet) {
			@include padding(48px x x 48px);
		}

		@include mq($bp__tablet) {
			@include padding(64px x x 64px);
		}

		&:before {
			@include absolute(0 x x 0);
		}
	}

	.blog-archive__item:nth-child(even)  & {
		@include padding(32px 32px x x);

		@include mq($bp__phablet) {
			@include padding(48px 48px x x);
		}

		@include mq($bp__tablet) {
			@include padding(64px 64px x x);
		}

		&:before {
			@include absolute(0 0 x x);
		}
	}
}

.blog-archive__shape--triangle {
	&:before {
		content: "";
		@include svg(blog__shape--triangle, 100%);
		@include size(125px);
		display: block;

		@include mq($bp__phablet) {
			@include size(188px);
		}

		@include mq($bp__tablet) {
			@include size(250px);
		}
	}

	.blog-archive__item:nth-child(odd) & {
		@include padding(32px x x 32px);

		@include mq($bp__phablet) {
			@include padding(48px x x 48);
		}

		@include mq($bp__tablet) {
			@include padding(64px x  x 64px);
		}

		&:before {
			@include absolute(0 x x 0);
		}
	}

	.blog-archive__item:nth-child(even)  & {
		@include padding(32px 32px x x);

		@include mq($bp__phablet) {
			@include padding(48px 48px x x);
		}

		@include mq($bp__tablet) {
			@include padding(64px 64px x x);
		}

		&:before {
			@include absolute(0 0 x x);
		}
	}
}

.blog-archive__shape--circle {
	
	&:before {
		content: "";
		@include svg(blog__shape--circle, 100%);
		@include size(125px);
		display: block;

		@include mq($bp__phablet) {
			@include size(188px);
		}

		@include mq($bp__tablet) {
			@include size(250px);
		}
	}

	.blog-archive__item:nth-child(even) & {
		@include padding(62.5px 62.5px x x);

		@include mq($bp__phablet) {
			@include padding(94px 94px x x);
		}

		@include mq($bp__tablet) {
			@include padding(125px 125px x x);
		}

		&:before {
			@include absolute(0 0 x x);
		}
	}

	.blog-archive__item:nth-child(odd) & {
		@include padding(62.5px x x 62.5px);

		@include mq($bp__phablet) {
			@include padding(94px x x 94px);
		}

		@include mq($bp__tablet) {
			@include padding(125px x x 125px);
		}

		&:before {
			@include absolute(0 x x 0);
		}
	}
}

.blog-archive__item--has-circle {
	
	@include mq($bp__tablet) {
		margin-top: -62.5px;
	}
}

.blog-archive__item--horiz {
	max-width: 800px;
}

.blog-archive__item--vert {
	max-width: 700px;
}

.blog-archive__hgroup {
	background-color: $beige;
	padding: 24px 0 0;	

	@include mq($bp__desktop) {
		padding: 36px 36px 0;
		position: absolute;
	}

	.blog-archive__item--horiz & {
		
		@include mq($bp__desktop) {
			bottom: 0;
			width: 50%;
		}
	}

	.blog-archive__item--vert & {
		
		@include mq($bp__desktop) {
			bottom: 16%;
			padding-bottom: 36px;
			width: 50%;
		}
	}
	
	.blog-archive__item--vert:nth-child(even) & {
		
		@include mq($bp__desktop--xl) {
			margin-left: -50%;
			width: 75%;
		}
	}

	.blog-archive__item--vert:nth-child(odd) & {
		
		@include mq($bp__desktop) {
			right: 0;
			margin-right: -50%;
		}
	}

	.blog-archive__item--horiz:nth-child(odd) & {
		
		@include mq($bp__desktop) {
			right: 0;
		}
	}
}

.blog-archive__img-container {
	overflow: hidden;
}

.blog-archive__img {
	max-width: 100%;
	width: 100%;
	display: block;
	opacity: 1;
	transition: transform 2s $timing--butter,
							opacity 2s $timing--butter;

	.no-touchevents .blog-archive__item:hover & {
		transform: scale(1.2, 1.2);
		opacity: 0.8;
	}
}

// 
// Blog Home Type
// 

.blog-archive__h {
	font-size: 6.25vw;
	margin-bottom: 0;

	@include mq($bp__tablet) {
		font-size: 36px;
	}
}

.blog-archive__txt {
	font-size: 18px;
	margin-bottom: 0;
	margin-top: 12px;

	@include mq($bp__tablet--sm) {
		font-size: 24px;
	}
}

.blog-archive__h-link {
	text-decoration: none;
}

.blog-archive__by-line {
	@include sans(16px, "bold");
  color: $link-gray;
  line-height: 1;
  margin-top: 15px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;

  @include mq($bp__tablet) {
  	font-size: 18px;
  }
}

//
// Single Blog Layout
//

.l-blog {
	max-width: 1000px;
}

.blog__content {
	position: relative;
}

//
// Single Blog Type
//

.blog__byline {
	margin: 18px 0 24px;

	@include mq($bp__phablet) {
		max-width: $blog-width;
	  font-size: 22px;
	 }

  @include mq($bp__tablet) {
  	@include padding(0 0 0 80px);
  }
}

.blog__name {
	@include sans(18px, bold);
	color: $blue-dk;

	@include mq($bp__phablet) {
		font-size: 24px;
	}
}

.blog__date {
	@include sans(18px);
	color: $gray;

	@include mq($bp__phablet) {
		font-size: 24px;
	}
}

.blog__responsive-video {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	margin-top: 30px;

	iframe {
		@include absolute(0 x x 0);
		@include size(100%);
	}
}

.blog__content {
	.wp-caption {
		max-width: 100%;
		height: auto;
		margin: 24px 0 0;
		width: auto !important;

		img {
			height: auto;
			margin-bottom: 0;
			width: 100%;
		}
	}

	.wp-caption-text {
		@include sans(18px);
		margin: 24px 0 24px;
		color: $gray;
		text-align: center;
		padding: 0;
	}

	> p {
		@include sans(18px);
	  line-height: 1.5;
	  color: $blue-dk;
	  width: 100%;
		margin-top: 0;

	  @include mq($bp__phablet) {
	  	max-width: $blog-width;
	    font-size: 22px;
	  }
	  @include mq($bp__tablet) {
	  	@include padding(0 0 0 80px);
	  }
	}
	
	a {
		transition: color 0.2s $timing--butter;

		.no-touchevents &:hover {
			color: $red;
		}
	}

	a,
	p a {
		color: $blue;
		text-decoration: none;
		font-weight: bold;

		.no-touchevents &:hover {
			color: $gray;
		}
	}

	> h2 {
		@include serif(36px, bold);
		width: 100%;
		margin-top: 0;
		margin-bottom: 10px;
		line-height: 1;
		color: $red;

		@include mq($bp__phablet) {
	  	max-width: $blog-width;
	    font-size: 64px;
	    margin-bottom: 18px;
	    margin-top: 36px;
	  }

	  @include mq($bp__tablet) {
	  	@include padding(0 0 0 80px);
	  	margin-top: 48px;
	  }
	}

	> h3 {
		@include sans(24px, bold);
		width: 100%;
		margin-top: 0;
		margin-bottom: 10px;

		@include mq($bp__phablet) {
	  	max-width: $blog-width;
	    font-size: 32px;
	  }

	  @include mq($bp__tablet) {
	  	@include padding(0 0 0 80px);
	  }
	}

	ul {
		padding-left: 21px;

		@include mq($bp__tablet) {
	  	@include padding(0 0 0 100px);
	  }
	}

	ol {
		padding-left: 24px;

		@include mq($bp__phablet) {
	  	max-width: $blog-width;
	    font-size: 64px;
	    margin-bottom: 18px;
	  }

	  @include mq($bp__tablet) {
	  	@include padding(0 0 0 80px);
	  }
	}

	ul li {
		padding-bottom: 10px;

		@include mq($bp__tablet) {
			padding-bottom: 12px;
		}
	}
	
	ol li,
	ul li {
		@include sans(18px);

		@include mq($bp__phablet) {
			font-size: 22px;
		}

		a {
			text-decoration: none;
			color: $blue;
		}

		img {
			display: block;
			margin: 40px auto;
			height: auto;
		}
	}

	ol li {
		list-style: none;
		position: relative;
		counter-increment: question-counter;
		
		&:before {
			content: counter(question-counter) ".";
			@include absolute(0 x x -24px);
			@include sans(18px, "med");
			color: $red;

			@include mq($bp__phablet) {
				font-size: 22px;
			}			
		}
	}

	> img {
		margin: 40px auto;
		height: auto;
	}

	> blockquote {
		@include margin(x 0);
		@include serif(24px);
		color: $blue;
		position: relative;
		
		@include mq($bp__phablet) {
			font-size: 48px;
		}
		@include mq($bp__tablet) {
			font-size: 62px;
		}

		cite {
			color: $gray;
			font-style: normal;
			@include sans(18px);
			@include padding(24px x x x);

			@include mq($bp__tablet) {
				font-size: 24px;
			}
		}

		p {
			
			@include mq($bp__tablet) {
				text-indent: -22px;
			}
		}
	}
}

.blog__h {
	@include mq($bp__phablet) {
    font-size: 48px;
  }

  @include mq($bp__tablet) {
    font-size: 72px;
  }

  @include mq($bp__desktop) {
    font-size: 84px;
  }
}