// =============================================

// Sales Page

// =============================================

.sales-page {
	.hero__landing-h-group {
		padding-right: 50%;

		@include mq($bp__tablet) {
			padding-right: 0;
		}
	}

	.landing__section--details {
		padding-bottom: 0;
	}
	
	.verbatim__h {
		margin-top: 20px;
		text-indent: 0;
	}

	.ftr {
		display: none;
	}
}

.landing__section--sales {
	text-align: center;
}

.sales__sub-h {
	margin-top: 30px;

	@include mq($bp__desktop) {
		margin-top: 50px;
	}
}

.sales__gallery {
	position: relative;

	.swiper-pagination {
		left: 50%;
		top: auto;
		bottom: 0;
		right: auto;
	}
}

.sales__quote {
	max-width: 60%;
	margin: 0 auto;
	padding: 30px 0 60px;
	
	@include mq($bp__tablet) {
		width: 50%;
	}
}

.sales__logo {
	max-width: 180px;
	width: 100%;
}

.sales__item {
	@include mq($bp__desktop) {
		min-height: 550px;
	}
}

.sales__link-container {
	margin-top: auto;
}

.sales__link {
	position: relative;
	display: inline-block;
	padding-right: 15px;
	
	@include mq($bp__desktop) {
		margin-top: auto;
	}

  &:after {
    content: "›";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s $timing--butter;
  }

  &:hover:after {
    right: -6px;
  }

  &:before {
    @include psuedo(0 n n 90%);
    content: "—";
    transform-origin: center center;
    height: 1px;
    width: 0;
    overflow: hidden;
    transition: all .3s $timing--butter;
  }

  &:hover:before {
    left: 96%;
    width: 10px;
    overflow: visible;
  }
}

.hero__sales {
	.mc-input {
		border: 2px solid $blue-dk;
	}

	.mc-submit {
		color: $blue-dk;
		background: transparent;
	}

	.email-signup-form {
		.txt {
			display: none;
		}
	}

	.email-signup-form {
		opacity: 0;
		transition: all 0.2s ease 0.2s;
	}

	.sales__signup--active .email-signup-form {
		opacity: 1;
	}
}

.sales__button {
	@include sans(14px, 'bold');
	padding: 15px 30px;
	background: $blue;
	color: #FFFFFF;
	text-decoration: none;
	text-transform: uppercase;
	display: inline-block;
	margin-top: 20px;
	transition: all 0.2s $timing--butter;
	letter-spacing: 1px;

	@include mq($bp__tablet) {
		font-size: 18px;
	}

	&:hover {
		background-color: darken($blue, 5);
	}
}

.sales__signup {
	position: relative;
	margin-top: 20px;
}

.sales__cta {
	@include absolute(0 x x 0);
	@include sans(18px, 'bold');
	letter-spacing: 1px;
	background-color: $blue;
	border: 0;
	color: #FFFFFF;
	padding: 14px;
	text-transform: uppercase;
	z-index: 10;
	width: 200px;
	transition: all 0.2s ease;

	&:hover {
		background-color: $blue-dk;
	}

	.sales__signup--active & {
		width: 100%;
		opacity: 0;
	}
}

.sales__txtgroup {
	margin-bottom: 40px;

	@include mq($bp__tablet) {
		margin-bottom: 80px;
	}

	@include mq($bp__desktop) {
		margin-bottom: 100px;
	}
}

.sales__h2 {
	text-align: center;
	max-width: 700px;
	margin: 0 auto 20px;
}

.sales__text {
	@include sans(21px, 'regular');
	color: $blue-dark;

	@include mq($bp__tablet) {
		font-size: 24px;
	}
}

.sales__email {
	background: $blue-dk;
	position: relative;
	width: 100%;
	@include padding(60px 20px);

	@include mq($bp__tablet) {
		@include padding(80px x);
	}

	@include mq($bp__desktop) {
		@include padding(100px x);	
	}

	.mc-input {
		border: 2px solid #FFFFFF;
	}

	.mc-submit {
		background-color: transparent;
		color: $blue;
	}

	.mc-txt {
		display: none;
	}
}

.sales__email-content {
	max-width: 440px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.sales__h {
	text-align: center;
}

.sales__txt {
	color: #FFFFFF;
	text-align: center;
}

.sales__email-signup {
	margin: 30px auto 0;
	max-width: 400px;
}

.sales__logo-container {
	position: relative;
	padding-bottom: 35%;
	text-align: center;
}

.sales__logos {
	@include absolute(0 x x 0);
	width: 100%;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

.sales__logo-item {
	width: 100%;
	display: block;
	opacity: 0;
	transform: translateY(20px);
	position: relative;
}

.sales__logo-img {
	width: 100%;
	max-width: 100%;
	display: block;
}