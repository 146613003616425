// =============================================

// Universal

// =============================================

* { box-sizing: border-box; }

::selection {
  background-color: $blue;
  color: #fff;
}

a {
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}

strong { font-weight: bold;  }
em     { font-style: italic; }
i      { font-style: normal; }

body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  // Autoprefixer does not support these properties
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: “liga” on;
}

img {
  max-width: 100%;

  // together, this eliminates odd
  // extra space that can sometimes
  // appear beneath images
  display: block;
  line-height: 1;
}

figure {
  margin: 0;
  padding: 0;
}

// Show this text only for screen-readers, et al.
.a11y-only {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}
