// =============================================

// Email Nag

// =============================================

.nag {
  z-index: $z-index--nag;
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 10px;

  padding: 30px 35px;
  max-width: 300px;
  margin: auto;

  background-color: #fff;
  box-shadow: 0 0 10px -2px rgba(0,0,0,.4);
  text-align: center;

  opacity: 0;
  visibility: hidden;

  @include mq($bp__tablet--sm){
    left: auto;
  }

  // Animate into view
  .cssanimations.nag-active & {
    visibility: visible;
    animation: nag--in 1s $timing--easeOutQuint;
    animation-fill-mode: forwards;
  }

  // Animate out of view
  .cssanimations.nag-disabled & {
    animation: nag--out 1s $timing--easeOutQuint;
    animation-fill-mode: forwards;
  }

  // Fallback in case CSS animations aren't supported
  .no-cssanimations.nag-active & {
    opacity: 1;
  }
}

.nag__content {
  position: relative;
}

.nag__img {
  position: relative;
  width: 100%;
}

.img__nag {
  .nag__h {
    margin-top: 30px;
  }
}

.nag__img-container {
  margin: -30px -35px 0;
}

//
// Text
//

.nag__h {
  @include serif(23px);
  color: $red;
  margin: 0 0 8px;

  @include mq($bp__phablet) {
    font-size: 27px;
    display: block;
  }

  @include mq($bp__tablet--sm) {
    font-size: 32px;
  }
}

.nag__mc-text {
  font-size: 16px;
  margin-bottom: 20px;
  @include mq($bp__tablet--sm) {
    font-size: 17px;
  }
}


//
// Inputs
//

$nag__mc-height: 42px;

.nag__mc-input {
  padding: 10px;
  height: $nag__mc-height;
  font-size: 14px;
}

.nag__mc-submit {
  @include size($nag__mc-height - 10);
}


//
// Close Button
// - Follows styles from Magnific close button (mfp-close)
//

.nag__close {
  @include reset("button");

  position: absolute;
  right: 0;
  top: 0;

  width: 44px;
  height: 44px;
  line-height: 44px;

  font: normal 28px Arial, Baskerville, monospace;
  text-decoration: none;
  text-align: center;
  color: #000;

  opacity: 0.35;
  filter: alpha(opacity=35);
  transition: all .15s ease-in;

  .img__nag & {
    color: #FFF;
  }
}

.nag__close:hover,
.nag__close:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}



//
// Animations
//

@keyframes nag--in {
  0% {
    opacity: 0;
    transform: translate3d(0, 75px, 0);
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes nag--out {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  25% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
    transform: translate3d(0, 75px, 0);
  }
}
