// =============================================

// Details

// =============================================

//
// Details Layout
//

.l-dtl__section {
  padding-top: 100px;
}

//
// Typography
//

.dtl__txtgroup {
  margin-bottom: 40px;
  text-align: center;
  @include mq($bp__phablet) {
    margin-bottom: 80px;
  }
}

.dtl__txt {
  margin: 0 auto;
  max-width: $txt-max-width--lg;
}

.dtl__sub-h {
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 19px;
  margin-bottom: 40px;

  @include mq($bp__tablet--sm) {
    font-size: 24px;
  }

  &:before {
    border-width: 3px;
    bottom: -5px;
    @include mq($bp__tablet--sm) {
      bottom: -6px;
      border-width: 4px;
    }
  }
}

.dtl__link {
  color: $blue;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: $gray;
  }
}

// --------------------------------------------
// Hero
// --------------------------------------------

.l-dtl__section--gray-bg {
  .bg-alt--gray & {
    background-color: $bg--gray;
  }
}

.hero__hgroup {
	margin-bottom: 70px;
	padding-bottom: 20px;
	text-align: center;
}

.hero__h {
  margin: 0 0 12px 0;
  @include serif(80px);
  color: $blue;
}

.hero__deck {
  @include sans(21px);
  color: $blue-dk;
  margin: 0 auto 16px;
  @include mq($bp__phone) {
    font-size: 23px;
  }
  @include mq($bp__phablet) {
    font-size: 25px;
  }
  @include mq($bp__tablet--sm) {
    font-size: 31px;
    margin-bottom: 20px;
  }
  @include mq($bp__tablet) {
    font-size: 34px;
  }
}

//
// Hero Image
//

.dtl-page {
  .hdr--overlay .hdr__wrapper {
    background: 0;
  }
}

.l-dtl__section--hero {
  padding-bottom: 60px;
}

.hero__img--details {
  width: 100%;
}

.hero__hgroup--details {
  margin-bottom: 0;
  padding-bottom: 0;
}

.hero__h--details {
  font-size: 50px;

  @include mq($bp__phone) {
    font-size: 58px;
  }

  @include mq($bp__phablet) {
    font-size: 70px;
    margin-bottom: 16px;
  }
  @include mq($bp__tablet--sm) {
    font-size: 100px;
    margin-bottom: 20px;
  }
  @include mq($bp__tablet) {
    font-size: 120px;
  }
  @include mq($bp__desktop) {
    font-size: 130px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  @include mq($bp__desktop--xl) {
    font-size: 150px;
  }
}

// --------------------------------------------
// Materials
// --------------------------------------------

.l-dtl-material-grid {
  @include clearfix;
  position: relative;

> .l-dtl-material-grid__block {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    @include mq($bp__tablet--sm) {
      margin-bottom: 0;
      width: 50%;
      float: left;
    }
    &:nth-child(1) {
      @include mq($bp__tablet--sm) {
        padding-right: 10px;
      }
    }
    &:nth-child(2) {
      @include mq($bp__tablet--sm) {
        padding-left: 10px;
      }
    }
  }
}

.l-dtl-material-grid__txt {
  position: absolute;
  @include sans(18px, bold);
  padding: 15px 15px;
  margin: 0;

  @include mq($bp__tablet--sm) {
    padding: 25px 30px;
  }
}

.l-dtl-material-grid__txt--white {
  color: #fff;
}

@mixin txt-position($posY, $posX) {
  .l-dtl-material-grid__txt--#{$posY}-#{$posX} {
    #{$posY}: 0;
    #{$posX}: 0;
    text-align: #{$posX};
  }
}

@include txt-position(top, left);
@include txt-position(top, right);
@include txt-position(bottom, left);
@include txt-position(bottom, right);

//
// Objects
//

.materials__buy-btn {
  margin: -70px auto -50px auto;
  background: #fff;
  box-shadow: 5px 5px 0 #fff, -5px -5px 0 #fff;
  z-index: $z-index--1;

  @include mq($bp__phablet) {
    border-width: 4px;
    margin: -95px auto -70px auto;
    @include size($buy-btn-size--md);
  }
  @include mq($bp__tablet--sm) {
    margin: -75px auto -75px auto;
  }
  @include mq($bp__tablet) {
    margin: -90px auto -90px auto;
    @include size($buy-btn-size--lg);
  }
}

.materials__buy-btn__inner {
    top: 12%;
    left: 77%;
  @include mq($bp__tablet) {
    top: 17%;
    left: 71%;
  }
  @include mq($bp__desktop) {
    top: 12%;
    left: 77%;
  }
}

// --------------------------------------------
// Installation
// --------------------------------------------

.l-dtl__section--install {
  padding-top: 80px;
}

.l-dtl-install-grid{
  @include clearfix;
  >*:nth-child(1) {
    padding-bottom: 30px;
    @include mq($bp__tablet) {
      width: 40%;
      float: right;
      @include padding(x 25px 0 25px);
    }
  }
  >*:nth-child(2) {

    @include mq($bp__tablet) {
      float: left;
      width: 60%;
      @include padding(x 25px x 25px);
    }
  }
}

.l-dtl-grid--half {
  @include mq($bp__phone) {
    // @include grid(40% 60%, 40px);
   }
  @include mq($bp__phone) {
    @include grid(2, 20px);
  }
  @include mq($bp__tablet) {
    @include grid(1);
  }
}

.txt--install {
  margin-top: 30px;
  @include mq($bp__tablet) {
    margin-top: 60px;
  }
}

// --------------------------------------------
// Details
// --------------------------------------------

.l-dtl__section--details {
  margin-top: 80px;
}

.l-detail-sideview {
  position: relative;

  @include mq($bp__tablet) {
    margin-top: 40px;
  }
}

.l-sideview__left {
  width: 100%;
  position: relative;
  @include mq($bp__desktop) {
    padding-right: 30%;
  }
}

.l-sideview__right {
  position: relative;
  margin: 0 auto;
  z-index: $z-index--1;
  @include padding(x 25px x 25px);
  @include mq($bp__desktop) {
    @include absolute(-80px 0 x x);
    padding-right: 10%;
    top: 0;
  }
}

.detail__h {
  text-transform: uppercase;
  margin: 0 auto;
  font-size: 21px;

  &:before {
    border-color: $red;
    border-width: 4px;
    bottom: -6px;
  }
}

//
// Spec Shape
//

.spec__shape {
  width: 100%;
  max-width: 400px;
  @include margin(0 auto 70px auto);
  @include mq($bp__tablet) {
    margin-bottom: 60px;
    padding-top: 20px;
  }
  @include mq($bp__desktop--xl) {
    padding-top: 50%;
    width: 300px;
  }
}

.spec__title {
  text-align: center;
}

.spec-shape__list {
  @include clearfix;
  @include reset(ul);
  position: relative;
}

.spec-shape__item {
  border-bottom: 1px solid $red;
  width: 100%;
  @include mq(400px, $bp__desktop) {
    width: 50%;
    float: left;
  }
}

.txt--dimensions {
  margin: 10px 0;
  line-height: 1;
}

.txt--spec {
  position: absolute;
  color: $red;
  padding-left: 15px;

  @include mq($bp__desktop) {
    padding-left: 0;
  }
}

.txt--number {
  display: block;
  width: 100%;
  padding-left: 75%;

  @include mq(400px) {
    padding-left: 50%;
  }
  @include mq($bp__desktop) {
    padding-left: 100px;
  }

  @include mq($bp__desktop--xl) {
    padding-left: 138px;
  }
}

// --------------------------------------------
// Security
// --------------------------------------------

.l-dtl__section--security {
}

.l-dtl-security--img {
  position: relative;
  max-width: 280px;
  width: 100%;
  @include margin(-180px auto 20px auto);
  z-index: $z-index--2;

  @include mq($bp__desktop) {
    max-width: 400px;
  }
}

.img--cad {
  border-color: #fff;
}

.l-dtl-security__locking {
  position: relative;
}

.weather-icons {
  position: relative;
  max-width: 550px;
  width: 70%;
  @include margin(x auto -40px auto);
  z-index: $z-index--1;

  @include mq($bp__tablet--sm) {
    width: 80%;
    margin-bottom: -60px;
  }

  @include mq($bp__tablet) {
    width: 100%;
    margin-bottom: -80px;
  }
}

// --------------------------------------------
// Options
// --------------------------------------------

.l-dtl-options-grid {
  padding-top: 60px;
  padding-bottom: 30px;
  @include mq($bp__tablet) {
    @include grid(3, 20px);
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.options__h {
  position: relative;
  @include sans(20px, bold);
  color: $blue-dk;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  display: inline-block;
  z-index: $z-index--1;
  text-decoration: none;
  transition: all 0.2s $timing--butter;
  margin: 0;

  @include mq($bp__tablet) {
    @include sans(16px, bold);
  }

  &:after {
    content: "";
    @include absolute(x x -6px 50%);
    border-bottom: 3px solid transparent;
    width: 0%;
    margin: 0 auto;
    transition: all 0.2s $timing--butter;

    .dtl__options--steel & {
      border-color: $blue-dk;
    }
    .dtl__options--tomato & {
      border-color: $red;
    }
    .dtl__options--navy & {
      border-color: lighten($navy, 40%);
    }
  }

  .no-touchevents .dtl__options:hover & {
    &:after {
      width: 100%;
      left: 0;
    }
  }
}

.dtl__options {
  text-align: center;
  padding-top: 50px;

  &:nth-child(1) {
    padding-top: 0;
  }
  @include mq($bp__tablet) {
    padding-top: 0;
  }
}

.dtl__options-img {
  transition: all 0.2s $timing--butter;
  max-width: 250px;
  margin: 0 auto;

  @include mq($bp__phablet) {
    max-width: 350px;
  }

  @include mq($bp__tablet) {
    max-width: 100%;
    margin: 0;
  }

  .no-touchevents .dtl__options:hover & {
    opacity: 0.9;
    transform: scale(1.05, 1.05);
  }
}

// --------------------------------------------
// Frequent Questions
// --------------------------------------------

.dtl-faq__list {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;

  .no-touchevents &:hover {
    &:after {
      opacity: 0;
      transition-duration: 0.2s;
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: -5px;
    background: linear-gradient(rgba(#fff, 0) 25%, rgba(#fff, 1));
    pointer-events: none;

    transition-property: opacity;
    transition-duration: 0.9s;
    transition-timing-function: $timing--butter;
  }
}

.dtl-faq__link {
  text-decoration: none;
  transition: all 0.2s $timing--butter;
}

.txt__link--faq {
  margin-top: 50px;
}

.txt--faq {
  font-size: 40px;
  @include mq($bp__phablet) {
    font-size: 68px;
  }
  @include mq($bp__tablet) {
    font-size: 100px;
  }
}
