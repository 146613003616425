// =============================================

// Frequent Questions

// =============================================

.l-faq__section {
	width: 100%;
	text-align: center;
	margin: 0 auto;
	@include padding(100px 20px x 20px);
}

.faq__link {
	text-decoration: none;
	display: inline-block;
}

.faq__h-link,
.faq__sub-h-link {
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s $timing--butter;
}

.faq__sub-h-link {
	position: relative;
	&:before {
		content: "<";
		@include sans(26px, bold);
		height: 30px;
		width: 30px;
		@include absolute(16px x x -30px);
		opacity: 0;
		transform: translate(10px, 0);
		transition: all 0.2s $timing--butter;

		@include mq($bp__tablet--sm) {
			top: 20px;
			font-size: 32px;
		}
	}
	.no-touchevents &:hover:before {
		opacity: 1;
		transform: translate(0, 0);
	}
}

.js--not-in-view {
	opacity: 0.5;
}

.faq__h-group {
	position: relative;
}

.faq__h {
	text-align: center;
	margin-bottom: 6px;

	@include mq($bp__phablet, max) {
    font-size: 42px;
  }
}

.faq__content {
	width: 100%;
	max-width: 640px;
	@include margin(0 auto 80px auto);
	text-align: left;

	// Text styles
	@include sans(18px);
	line-height: 1.5;
	color: $blue-dk;

	@include mq($bp__phablet) {
    margin-bottom: 100px;
		font-size: 22px;
  }

  @include mq($bp__tablet--sm) {
    margin-bottom: 120px;
  }

  a {
  	color: $red;
  	text-decoration: none;
  	&:hover {
  		text-decoration: underline;
  	}
  }

}